<template>
    <div id="layoutEditorPg" :class="{'syouyo':viewShouyo}">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <span>
                    明細タイプ：
                    <select class="select_meisaiType" v-model="meisaiType">
                        <option v-for="(itm, ix) in meisaiTypes" :value="itm.value" :key="ix">{{itm.name}}</option>
                    </select>
                </span>
                <span>
                    給与タイプ：
                    <select class="select_salaryType" v-model="salaryType">
                        <option v-for="(itm, ix) in salaryTypes" :value="itm.value" :key="ix">{{itm.name}}</option>
                    </select>
                </span>
                <span>
                    リスト：
                    <select class="select_rekiList" v-model="activeStartDate">
                        <option v-for="(itm, ix) in rekiList" :value="itm.value" :key="ix">{{itm.name}}</option>
                    </select>
                </span>
            </div>
            <!-- 給与明細 -->
            <div class="detail">
                <div class="header">
                    <div class="panel">
                        <span>開始日：<input type="date" v-model="startDate" @blur="sdtFocusOut" @keyup="edit_keyup" /></span>
                    </div>
                    <div class="sign tbl">

                    </div>
                    <div class="shikyuday">支給日<div class="shikyuday_panel">令和 X年 X月 X日</div></div>
                </div>
                <div class="vspace"></div>
                <div class="list_panel">
                    <div class="kintai_panel detail_panel">
                        <div class="title">勤怠</div>
                        <div class="tbl">
                            <layoutCell v-for="(item, index) in kintaiList" :item="item" :keyName="index" @clickcell="clickcellKin" :key="index"></layoutCell>
                        </div>
                        <div class="vps"></div>
                        <div class="sashihiki_panel tbl">
                            <div class="tbl">
                                <div class="tblrow">
                                    <div class="namecell tblcell">税額表</div>
                                    <div class="formatcell tblcell"></div>
                                </div>
                                <div class="tblrow">
                                    <div class="namecell tblcell">扶養人数</div>
                                    <div class="formatcell tblcell"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="shikyu_panel detail_panel">
                        <div class="title">支給</div>
                        <div class="tbl">
                            <layoutCell v-for="(item, index) in shikyuList" :item="item" :keyName="index" @clickcell="clickcellShi" :key="index"></layoutCell>
                        </div>
                    </div>
                    <div class="koujyo_panel detail_panel">
                        <div class="title">控除</div>
                        <div class="tbl">
                            <layoutCell v-for="(item, index) in koujoList" :item="item" :keyName="index" @clickcell="clickcellKou" :key="index"></layoutCell>
                        </div>
                    </div>
                    <div class="etc_panel detail_panel">
                        <div class="title">その他</div>
                        <div class="tbl">
                            <layoutCell v-for="(item, index) in otherList" :item="item" :keyName="index" @clickcell="clickcellOth" :key="index"></layoutCell>
                        </div>
                        <div class="vps"></div>
                        <div class="sashihiki_panel tbl">
                            <div class="tbl">
                                <div class="tblrow">
                                    <div class="namecell tblcell">差引支給額</div>
                                    <div class="formatcell tblcell"></div>
                                </div>
                            </div>
                        </div>
                        <div class="vps"></div>
                        <div class="furikomi_panel title">振込支給額</div>
                        <div class="tbl">
                            <layoutCell v-for="(item, index) in furikomiList" :item="item" :keyName="index" @clickcell="clickcellFur" :key="index"></layoutCell>
                        </div>
                        <div class="vps"></div>
                        <div class="sashihiki_panel tbl">
                            <div class="tbl">
                                <div class="tblrow">
                                    <div class="namecell tblcell">現金支給額</div>
                                    <div class="formatcell tblcell"></div>
                                </div>
                            </div>
                        </div>
                        <div class="vps"></div>
                        <div class="sashihiki_panel tbl">
                            <div class="tbl">
                                <div class="tblrow">
                                    <div class="namecell tblcell">現物支給額</div>
                                    <div class="formatcell tblcell"></div>
                                </div>
                            </div>
                        </div>
                        <div class="vps"></div>
                        <div class="sashihiki_panel tbl">
                            <layoutCell v-for="(item, index) in otherList2" :item="item" :keyName="index" @clickcell="clickcellOth2" :key="index"></layoutCell>
                        </div>
                    </div>
                </div>
                <div class="vspace"></div>
                <div class="footer">
                    <div class="buttons_area">
                        <button class="send" @click.stop="saveData">登録</button><button class="delete" @click.stop="deleteData">削除</button>
                    </div>
                </div>
            </div>
            <!-- フッター -->
            <div class="footer">
            
            </div>
        </div>
        <div id="editorWindow" v-if="showEditor">
            <div class="header_area">
                <div class="close-win" @click="close_win">✕</div>
            </div>
            <div class="detail_area">
                <div class="tbl">
                    <div class="tblrow">
                        <div class="tblcell">項目名：</div>
                        <div class="tblcell"><input type="text" v-model="editName"/></div>
                    </div>
                    <div class="tblrow">
                        <div class="tblcell">コマンド：</div>
                        <div class="tblcell">
                            <select v-model="editCommand">
                                <option v-for="(itm, ix) in commandList" :value="itm.value" :key="ix">{{itm.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="tblrow">
                        <div class="tblcell">フォーマット：</div>
                        <div class="tblcell">
                            <select v-model="editFormat">
                                <option v-for="(itm, ix) in formats" :value="itm.value" :key="ix">{{itm.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="locked_area tblrow">
                        <div class="tblcell">ロック：</div>
                        <div class="tblcell">
                            <label><input type="radio" name="locked" v-model="editLocked" value="0"/>&nbsp;ロック</label><label><input type="radio" name="locked" v-model="editLocked" value="1"/>&nbsp;名称不可</label><label><input type="radio" name="locked" v-model="editLocked" value="2"/>&nbsp;編集可</label>
                        </div>
                    </div>
                    <div class="tblrow">
                        <div class="tblcell">役員使用：</div>
                        <div class="tblcell"><label><input type="checkbox" v-model="editOfficer"/>&nbsp;役員は使用しない</label></div>
                    </div>
                </div>
            </div>
            <div class="footer_area">
                <button class="btnOk" @click.stop="editorOk">決定</button>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * 給与明細レイアウト編集画面
 */
import Vue from 'vue';
import layoutCell  from '@/components/layoutCell.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "layoutEditorPg",
    mixins: [utilityMixin],
    components: {
        'layoutCell': layoutCell
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        meisaiType(nv, ov) {
            if (nv == "kyuyo") {
                this.viewShouyo = false;
            } else {
                this.viewShouyo = true;
            }
            this.getRekiList();
        },
        salaryType(nv, ov) {
            this.getRekiList();
        },
        activeStartDate(nv, ov) {
            this.getData();
        },
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            headerTitle: "レイアウト編集",
            accountId: "",
            meisaiType: "kyuyo",
            salaryType: "gekyu",
            activeStartDate: "",
            activeList: "",
            activeKey: "",
            startDate: "",
            rekiList: [],
            kintaiList: [],
            shikyuList: [],
            koujoList: [],
            otherList: [],
            furikomiList: [],
            otherList2: [],
            meisaiTypes: [
                {name:"給与", value:"kyuyo"},
                {name:"賞与", value:"shouyo"}
            ],
            salaryTypes: [
                {name:"月給", value:"gekyu"},
                {name:"パート", value:"part"},
                {name:"アルバイト", value:"baito"},
            ],
            viewShouyo: false,
            editName: "",
            editCommand: "",
            editFormat: "",
            editLocked: 2,
            editOfficer: false,
            showEditor: false,
            commandList: [],
            formats: []
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }

        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;

            this.getRekiList();
        });
    },
    methods: {
        async getRekiList() {
            try {
                this.activeStartDate = "";

                let param = {
                    "meisaiType": this.meisaiType,
                    "salaryType": this.salaryType
                };

                let ress = await Vue.$cache.post('layout/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.rekiList = ress.data.data.list;
                        if (this.rekiList.length > 0) {
                            this.activeStartDate = this.rekiList[0].value;
                        } else {
                            this.activeStartDate = "";
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async getData() {
            try {
                if (this.activeStartDate == "") return;
                
                this.startDate = "";
                this.kintaiList = [];
                this.shikyuList = [];
                this.koujoList = [];
                this.otherList = [];
                this.furikomiList = [];
                this.otherList2 = [];

                let param = {
                    "meisaiType": this.meisaiType,
                    "salaryType": this.salaryType,
                    "startDate": this.activeStartDate
                };

                let ress = await Vue.$cache.post('layout/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.startDate = ress.data.data.startDate;
                        this.kintaiList = ress.data.data.kintaiList;
                        this.shikyuList = ress.data.data.shikyuList;
                        this.koujoList = ress.data.data.koujoList;
                        this.otherList = ress.data.data.otherList;
                        this.furikomiList = ress.data.data.furikomiList;
                        this.otherList2 = ress.data.data.otherList2;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        saveData() {
            if (!confirm("登録しますか？")) {
                return false;
            }
            this.saveDataEx();
        },
        async saveDataEx() {
            try {
                let param = {
					"meisaiType": this.meisaiType,
                    "salaryType": this.salaryType,
                    "startDate": this.startDate,
                    "userId": this.accountId,
                    "kintaiList": this.kintaiList,
                    "shikyuList": this.shikyuList,
                    "koujoList": this.koujoList,
                    "otherList": this.otherList,
                    "furikomiList": this.furikomiList,
                    "otherList2": this.otherList2,
                };
                let ress = await Vue.$cache.post('layout/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.getRekiList();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        deleteData() {
            if (!confirm("削除しますか？")) {
                return false;
            }
            this.deleteDataEx();
        },
        async deleteDataEx() {
            try {
                let param = {
					"meisaiType": this.meisaiType,
                    "salaryType": this.salaryType,
                    "startDate": this.activeStartDate,
                    "userId": this.accountId
                };
                let ress = await Vue.$cache.post('layout/delete/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.getRekiList();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        edit_keyup(e) {
            if ((e.keyCode == 13) || (e.keyCode == 9)) {
                /*
                if (this.activeStartDate != this.startDate) {
                    this.activeStartDate = "";
                }
                return;
                */
            }
        },
        sdtFocusOut() {
            /*
            if (this.activeStartDate != this.startDate) {
                this.activeStartDate = this.startDate;
            }
            */
        },
        clickcellKin(key) {
            this.openEdit(key, "kintaiList");
        },
        clickcellShi(key) {
            this.openEdit(key, "shikyuList");
        },
        clickcellKou(key) {
            this.openEdit(key, "koujoList");
        },
        clickcellOth(key) {
            this.openEdit(key, "otherList");
        },
        clickcellFur(key) {
            this.openEdit(key, "furikomiList");
        },
        clickcellOth2(key) {
            this.openEdit(key, "otherList2");
        },
        openEdit(key, listName) {
            this.activeList = listName;
            this.activeKey = key;

            this.editName = this[listName][key].name;
            this.editCommand = this[listName][key].command;
            this.editFormat = this[listName][key].format;
            this.editLocked = this[listName][key].locked - 0;
            this.editOfficer = (this[listName][key].officer == 1) ? true : false;

            this.formats = this.getFormats(listName);
            this.commandList = this.getCommandList(listName);

            this.showEditor = true;
            this.$store.dispatch("operator_backPanel", "on");
        },
        editorOk() {
            this[this.activeList][this.activeKey].name = this.editName;
            this[this.activeList][this.activeKey].command =this.editCommand;
            this[this.activeList][this.activeKey].format = this.editFormat;
            this[this.activeList][this.activeKey].locked = this.editLocked;
            this[this.activeList][this.activeKey].officer = (this.editOfficer == true) ? 1 : 0;

            this.close_win();
        },
        close_win() {
            this.showEditor = false;
            this.$store.dispatch("operator_backPanel", "");
        },
        getFormats(listName) {
            let result = [];

            if (listName == "kintaiList") {
                result = [
                    {"name":"", "value":""},
                    {"name": "数値", "value": "0.00"},
                    {"name": "時間", "value": "H:MM"},
                    {"name": "通貨", "value": "0,000"}
                ];
            } else {
                result = [
                    {"name":"", "value":""},
                    {"name": "通貨", "value": "0,000"}
                ];
            }

            return result;
        },
        getCommandList(listName) {
            let result = [];

            if (listName == "kintaiList") {
                if (this.meisaiType == "kyuyo") {
                    result = [
                        {"name":"", "value":""},
                        {"name":"所定就労日", "value":"syoteiDays"},
                        {"name":"出勤日数", "value":"syukinDays"},
                        {"name":"所定労働時間", "value":"func_syoteiTimes"},
                        {"name":"法休出勤日数", "value":"kyujitsuDays"},
                        {"name":"法外出勤日数", "value":"houteigaiDays"},
                        {"name":"有給休暇日数", "value":"yukyuDays"},
                        {"name":"特別休暇日数", "value":"tokukyuDays"},
                        {"name":"普通残業時間", "value":"jikangaiTimes"},
                        {"name":"深夜残業時間", "value":"sinyaTimes"},
                        {"name":"法休出勤時間", "value":"kyujitsuTimes"},
                        {"name":"法外出勤時間", "value":"houteigaiTimes"}
                    ];
                } else {
                    result = [
                        {"name":"", "value":""}
                    ];
                }
            } else if (listName == "shikyuList") {
                if (this.meisaiType == "kyuyo") {
                    result = [
                        {"name":"", "value":""},
                        {"name":"基本給（月給）", "value":"func_kihonkyu"},
                        {"name":"地域手当", "value":"func_bukka"},
                        {"name":"技術手当", "value":"func_gijyutsu"},
                        {"name":"普通残業手当", "value":"func_zangyo"},
                        {"name":"深夜残業手当", "value":"func_shinya"},
                        {"name":"法休出勤手当", "value":"func_kyujitsu"},
                        {"name":"法外出勤手当", "value":"func_houteigai"},
                        {"name":"非課税通勤費", "value":"func_koutsuhi"},
                        {"name":"合計", "value":"func_sum"},
                    ];
                } else {
                    result = [
                        {"name":"", "value":""},
                        {"name":"賞与", "value":"func_kihonkyu"},
                        {"name":"合計", "value":"func_sum"}
                    ];
                }
            } else if (listName == "koujoList") {
                if (this.meisaiType == "kyuyo") {
                    result = [
                        {"name":"", "value":""},
                        {"name":"健康保険料", "value":"syaho"},
                        {"name":"介護保険料", "value":"kaigo"},
                        {"name":"厚生年金保険", "value":"nenkin"},
                        {"name":"雇用保険料", "value":"koyouHoken"},
                        {"name":"社会保険料計", "value":"func_syahoSum"},
                        {"name":"所得税", "value":"syotokuTax"},
                        {"name":"住民税", "value":"func_jyuminZei"},
                        {"name":"合計", "value":"func_sum"}
                    ];
                } else {
                    result = [
                        {"name":"", "value":""},
                        {"name":"健康保険料", "value":"syaho"},
                        {"name":"介護保険料", "value":"kaigo"},
                        {"name":"厚生年金保険", "value":"nenkin"},
                        {"name":"雇用保険料", "value":"koyouHoken"},
                        {"name":"社会保険料計", "value":"func_syahoSum"},
                        {"name":"所得税", "value":"syotokuTax"},
                        {"name":"合計", "value":"func_sum"}
                    ];
                }
            }

            return result;
        },
        base_click() {

        }
    }
}
</script>

<style lang="less">
&:root {
    --borderColor: #8EA9DB;
    --backgroundColor: #D9E1F2;
    --color: #305496;
}

#layoutEditorPg {
    width: 100%;

    &.syouyo {
        --borderColor: #A9D08E;
        --backgroundColor: #E2EFDA;
        --color: #548235;
    }

    .main_area {
        position: relative;
        width: 100%;
        padding: 62px 12% 28px;
        overflow: auto;

        .header {
            width: 100%;
            padding: 0 0 8px;

            span {
                margin-right: 42px;
            }

            select {
                height: 28px;
            }
        }

        .detail {
            font-size: 10.5pt;
            background-color: #ffffff;
            color: #000000;
            margin-left: calc((100% - 912.13px) / 2);
            width: 912.13px;
            height: 620.11px;
            display: grid;
            grid-template-rows: 116.9px 11px 418.3px 11px auto;
            border: 1px solid var(--borderColor);

            .header {
                position: relative;
                height: 116.9px;

                .panel {
                    width: 428.71px;
                    height: 116.89px;
                    border-right: 1px solid var(--borderColor);
                    border-bottom: 1px solid var(--borderColor);
                    padding: 20px;
                }

                .shikyuday {
                    position: absolute;
                    left: 500px;
                    bottom: 0;
                    border-bottom: 1px solid var(--borderColor);
                    width: 240px;
                    padding: 0 20px;

                    .shikyuday_panel {
                        display: inline-block;
                        width: 140px;
                        text-align: center;
                        margin-left: 16px;
                    }
                }
            }

            .list_panel {
                height: 418.3px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                position: relative;

                .kintai_panel {
                    width: 187px;

                    .title {
                        border-right: 1px solid var(--borderColor);
                    }

                    .tbl {

                        .tblcell {
                            border-right: 1px solid var(--borderColor);
                        }

                        .namecell {
                            width: 114px;
                        }
                    }
                }

                .shikyu_panel, .koujyo_panel, .etc_panel {
                    width: 230.26px;

                    .title {
                        border-left: 1px solid var(--borderColor);
                    }

                    .tbl {
                        
                        .tblcell {
                            &:first-child {
                                border-left: 1px solid var(--borderColor);
                            }
                        }

                        .namecell {
                            width: 127.22px;
                        }
                    }
                }

                .shikyu_panel, .koujyo_panel {
                    .title {
                        border-right: 1px solid var(--borderColor);
                    }

                    .tbl {
                        
                        .tblcell {
                            border-right: 1px solid var(--borderColor);
                        }
                    }
                }

                .sashihiki_panel {
                    border-top: 1px solid var(--borderColor);
                }

                .detail_panel {
                    font-size: 9pt;

                    .title {
                        text-align: center;
                        border-top: 1px solid var(--borderColor);
                        border-bottom: 1px solid var(--borderColor);
                        background-color: var(--backgroundColor);
                        height: 21px;
                        color: var(--color);
                        letter-spacing: 12px;
                        padding-left: 12px;
                    }

                    .tbl {
                        width: 100%;

                        .tblcell {
                            border-bottom: 1px solid var(--borderColor);
                            padding: 0 4px;
                            height: 21px;
                            vertical-align: middle;

                            &:first-child {
                                border-right: 1px solid var(--borderColor);
                            }
                        }

                        .namecell {
                            background-color: var(--backgroundColor);
                            color: var(--color);
                        }
                    }
                }

                .vps {
                    height: 20px;
                }
            }

            .footer {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px;
                border-top: 1px solid var(--borderColor);
                text-align: center;
                background-color: var(--backColor);
                color: var(--foreColor);

                .buttons_area {
                    margin: 0;

                    button {
                        width: 180px;
                        padding: 3px 12px;
                    }

                    .send {
                        margin-right: 12px;
                    }

                    .delete {
                        margin-left: 12px;
                        background-color: red;
                        border: 2px solid red;
                        color: white;
                    }
                }
            }

            .vspace {
                height: 11px;
            }

            input[type="date"] {
                background-color: white;
                color: black;
                height: 28px;
            }
        }
    }

    #editorWindow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 406px;
        height: 300px;
        margin: auto;
        box-shadow: 3px 3px 8px 1px rgba(0,0,0,0.2);
        background-color: var(--backColor);
        border: 1px solid #acacac;
        border-radius: 8px;
        z-index: 99991;

        .header_area {
            .close-win {
                float: right;
                width: 32px;
                height: 32px;
                text-align: center;
                padding: 6px 0;
                cursor: pointer;
            }
        }

        .detail_area {
            margin-top: 32px;
            padding: 12px 20px;

            .tblcell {
                padding: 4px 0;
            }

            input[type="text"], select, option {
                height: 28px;
                padding: 0 4px;
            }

            .locked_area {
                label {
                    margin-right: 12px;
                }
            }
        }

        .footer_area {
            position: absolute;
            bottom: 24px;
            width: 100%;
            text-align: center;

            .btnOk {
                width: 90px;
                padding: 4px 12px;
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            margin-left: 20px;
        }
    }
}

@media(max-width:1027px) {
    #meisaiPg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;
        }
    }
}
</style>