import Vue from 'vue'
export default {
	protocol: process.env.VUE_APP_API_PROTOCOL,
	serverAddress: process.env.VUE_APP_API_SERVER,
	port: process.env.VUE_APP_API_PORT,
	api: "/"+process.env.VUE_APP_API_BASE_URL+"/",
	apionly: process.env.VUE_APP_API_ONLY,
	defaults: {
		startUrl: "start/",
		endUrl: "end/"
	},
	auth: {
		username: process.env.VUE_APP_API_USERNAME,
		password: process.env.VUE_APP_API_PASSWORD
	},
	cspSessionCookie: "",
	async post(url, param, store, request){
		let result = undefined;
		if (this.serverAddress == "") {
			this.serverAddress = location.hostname;
		}
		this.cspSessionCookie = store.state.cachedb.cspSessionCookie;
		
		result = await this.startApi(store, "post", url, param, request);
		if (result == undefined || result == "") {
			await this.endApi(this.cspSessionCookie);
			return result;
		}

		return result;
	},
	async post_ex(url, param, request, cspSessionCookie) {
		let result = undefined;
		
		if (this.apionly == 1) {
			url = this.api + url;
		} else {
			url = this.protocol + "://" + this.serverAddress + ":" + this.port + this.api + url;
		}
		if (cspSessionCookie != "") {
			url = url + "?CSPSHARE=1&CSPCHD=" + cspSessionCookie;
		}
		if (request == undefined){
			request = {
				timeout: 5000,
				withCredentials: true,
				responseType: 'json',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			};
		}
		try {
			result = await Vue.$http.post(url, param, request);
			if (result != undefined) {
				await this.endApi(cspSessionCookie);
			}
			return result;
		} catch (err) {
			console.error(err);
		}
		return result;
	},
	async get(url, param, store){
		let result = undefined;
		if (this.serverAddress == "") {
			this.serverAddress = location.hostname;
		}
		this.cspSessionCookie = store.state.cachedb.cspSessionCookie;

		result = await this.startApi(store, "get", url, param, undefined);
		if (result == undefined || result == "") {
			await this.endApi(this.cspSessionCookie);
			return result;
		}

		return result;
	},
	async get_ex(url, param, cspSessionCookie) {
		let result = undefined;
		
		if (this.apionly == 1) {
			url = this.api + url;
		} else {
			url = this.protocol + "://" + this.serverAddress + ":" + this.port + this.api + url;
		}
		if (cspSessionCookie != "") {
			url = url + "?CSPSHARE=1&CSPCHD=" + cspSessionCookie;
		}
		try {
			if ((param == "")||(param == undefined)||(param == null)) {
				result = await Vue.$http.get(url);
			} else {
				result = await Vue.$http.get(url, { params: param });
			}
			if (result != undefined) {
				await this.endApi(cspSessionCookie);
			}
			return result;
		} catch (err) {
			console.error(err);
		}
	},
	async startApi(store, mode, url, param, requestb) {
		let result = undefined;

		let startUrl = this.protocol + "://" + this.serverAddress + ":" + this.port + this.api + this.defaults.startUrl;
		if (this.apionly == 1) {
			startUrl = this.api + this.defaults.startUrl;
		}
		//let startUrl = this.defaults.startUrl;
		let cspSessionCookie = "";
		if (this.cspSessionCookie != "") {
			startUrl = startUrl + "?CSPSHARE=1&CSPCHD=" + this.cspSessionCookie;
			cspSessionCookie = this.cspSessionCookie;
		}
		try {
			let request = {
				timeout: 5000,
				withCredentials: true,
				responseType: 'json',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			};

			let response = await Vue.$http.post(startUrl, {}, request);
			if (response.status == 200) {
				if (this.cspSessionCookie !== response.data.CSPSessionCookie) {
					this.cspSessionCookie = response.data.CSPSessionCookie;
					cspSessionCookie = this.cspSessionCookie;
					store.commit("cachedb_cspSessionCookie", this.cspSessionCookie);
				}
				if (mode == "post") {
					result = await this.post_ex(url, param, requestb, this.cspSessionCookie);
				} else {
					result = await this.get_ex(url, param, this.cspSessionCookie);
				}
			}
			return result;
		} catch (err) {
			console.error(err);
		}
		return result;
	},
	async endApi(cspSessionCookie) {
		let endUrl = this.protocol + "://" + this.serverAddress + ":" + this.port + this.api + this.defaults.endUrl;
		if (this.apionly == 1) {
			endUrl = this.api + this.defaults.endUrl;
		}
		//let endUrl = this.defaults.endUrl;
		if (cspSessionCookie != "") {
			endUrl = endUrl + "?CSPSHARE=1&CSPCHD=" + cspSessionCookie;
		}
		try {
			await Vue.$http.post(endUrl);
		} catch (err) {
			console.error(err);
		}
		return;
	}
}
