<template>
    <div class="meisaiRow tblrow">
        <div class="namecell tblcell" @click="editName">{{item.name}}</div>
        <div class="valuecell tblcell" @click="editValue">{{item.value}}</div>
    </div>
</template>

<script>
export default {
    props: {
        item: null,
        keyName: null,
        confirmFlag: { type:Boolean, default:false }
    },
    methods: {
        editName(e) {
            if (document.getElementById("sideMenu").classList.contains("on_menu")) {
                return false;
            }
            if (this.confirmFlag) {
                return false;
            }

            e.stopPropagation();

            if (this.item.locked <= 1) {
                return false;
            }

            this.$emit('editName', this.keyName, e.target);

            return false;
        },
        editValue(e) {
            if (document.getElementById("sideMenu").classList.contains("on_menu")) {
                return false;
            }
            if (this.confirmFlag) {
                return false;
            }

            e.stopPropagation();

            if (this.item.locked <= 0) {
                return false;
            }

            this.$emit('editValue', this.keyName, e.target);

            return false;
        }
    }
}
</script>

<style lang="less">
.meisaiRow {
    position: relative;
}
</style>