<template>
    <div id="trialPg">
        <div>
            試算<span class="label">基準日：</span><input type="date" v-model="targetDate" />
        </div>
        <div>
            <div class="inPanel">生年月日：<input type="date" v-model="birthDate" /></div>
            <div class="inPanel">基本給：<input type="number" v-model="baseWage" /></div>
            <div class="inPanel">手当：<input type="number" v-model="allowance" /></div>
            <div class="inPanel">残業：<input type="number" v-model="overtime" /></div>
            <div class="inPanel">交通費：<input type="number" v-model="transCost" /></div>
            <div class="inPanel">扶養人数：<input type="number" v-model="fuyou" /></div>
            <div class="inPanel"><label><input type="checkbox" v-model="koyouFlg" />雇用保険あり</label></div>
            <div class="inPanelEnd"><button @click.stop="getData">計算</button></div>
        </div>
        <div>
            基本給：¥{{Number(baseWage).toLocaleString()}}<br>
            手当：¥{{Number(allowance).toLocaleString()}}<br>
            交通費：¥{{Number(transCost).toLocaleString()}}<br>
            社会保険：¥{{Number(syaho).toLocaleString()}}&nbsp;（¥{{Number(syaho / 2).toLocaleString()}}）<br>
            介護保険：¥{{Number(kaigo).toLocaleString()}}&nbsp;（¥{{Number(kaigo / 2).toLocaleString()}}）<br>
            厚生年金：¥{{Number(nenkin).toLocaleString()}}&nbsp;（¥{{Number(nenkin / 2).toLocaleString()}}）<br>
            雇用保険：¥{{Number(koyouHoken).toLocaleString()}}&nbsp;（会社／¥{{Number(koyouHokenCo).toLocaleString()}}, 個人／¥{{Number(koyouHokenPa).toLocaleString()}}）<br>
            社保・年金合計：¥{{Number(hokenTotal).toLocaleString()}}&nbsp;（会社／¥{{Number(hokenTotalCo).toLocaleString()}}, 個人／¥{{Number(hokenTotalPa).toLocaleString()}}）<br>
            所得税：¥{{Number(syotokuTax).toLocaleString()}}<br>
            支給額（住民税含む）：¥{{Number(sikyuGaku).toLocaleString()}}<br>
            人件費：¥{{Number(laborCosts).toLocaleString()}}
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "trialPg",
    mixins: [utilityMixin],
    data() {
        return {
            birthDate: "",
            baseWage: 0,
            allowance: 0,
            overtime: 0,
            transCost: 0,
            fuyou: 0,
            salaryType: "M",
            syaho: "",
            kaigo: "",
            nenkin: "",
            koyouHoken1: "",
            koyouHoken2: "",
            targetDate: "",
            syotokuTax: "",
            koyouFlg: false
        }
    },
    computed: {
        hokenTotal() {
            let total = (this.syaho - 0) + (this.kaigo - 0) + (this.nenkin - 0);
            if (this.koyouFlg) {
                total = total + (this.koyouHoken - 0);
            }
            return total;
        },
        hokenTotalCo() {
            let total = (this.syaho / 2) + (this.kaigo / 2) + (this.nenkin / 2);
            if (this.koyouFlg) {
                total = total + (this.koyouHoken1 - 0);
            }
            return total;
        },
        hokenTotalPa() {
            let total = (this.syaho / 2) + (this.kaigo / 2) + (this.nenkin / 2);
            if (this.koyouFlg) {
                total = total + (this.koyouHoken2 - 0);
            }
            return total;
        },
        koyouHoken() {
            let res = 0;
            if (this.koyouFlg) {
                res = (this.koyouHoken1 - 0) + (this.koyouHoken2 - 0);
            }
            return res;
        },
        koyouHokenCo() {
            let res = 0;
            if (this.koyouFlg) {
                res = this.koyouHoken1 - 0;
            }
            return res;
        },
        koyouHokenPa() {
            let res = 0;
            if (this.koyouFlg) {
                res = this.koyouHoken2 - 0;
            }
            return res;
        },
        sikyuGaku() {
            let total = (this.baseWage - 0) + (this.allowance - 0) + (this.transCost - 0) + (this.overtime - 0);

            total = total - this.hokenTotalPa - this.syotokuTax;

            return total;
        },
        laborCosts() {
            let total = (this.baseWage - 0) + (this.allowance - 0) + (this.transCost - 0) + (this.overtime - 0);

            total = total + (this.hokenTotalCo - 0)

            return total;
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }

        this.$nextTick(function() {
            let nowDate = new Date();
            this.targetDate = nowDate.toChar("yyyy-MM-dd");
        });
    },
    methods: {
        async getData() {
            try {
                this.koyouHoken = 0;
                let total = (this.baseWage - 0) + (this.allowance - 0);
                
                let param = {
					kenCd: "40",
                    birthDay: this.birthDate,
                    baseWage: total,
                    overtime: this.overtime,
                    transCost: this.transCost,
                    targetDay: this.targetDate,
                    fuyou: this.fuyou,
                    salaryType: this.salaryType,
                    koyouFlg: (this.koyouFlg ? 1 : 0)
                };
                let ress = await Vue.$cache.post('saraly/trial/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.syaho = ress.data.data.syaho;
                        this.kaigo = ress.data.data.kaigo;
                        this.nenkin = ress.data.data.nenkin;
                        this.koyouHoken1 = ress.data.data.koyouHoken1;
                        this.koyouHoken2 = ress.data.data.koyouHoken2;
                        this.syotokuTax = ress.data.data.syotokuTax;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        }
    }
}
</script>

<style lang="less">
#trialPg {

    .inPanel {
        float: left;
        margin-right: 22px;
    }

    .inPanelEnd {
        clear: left;
        margin-bottom: 22px;
    }

    span.label {
        margin-left: 22px;
    }
}

</style>