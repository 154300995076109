import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import UUID from 'vue-uuid'
import VueCookies from 'vue-cookies'
import 'vue-awesome/icons';
import Icon from 'vue-awesome/components/Icon';

import cachedb from '@/utils/cache-connect'
import utils from '@/utils/utility'

Vue.config.productionTip = false

Vue.$http = axios.create({
  auth: {
    username: process.env.VUE_APP_API_USERNAME,
    password: process.env.VUE_APP_API_PASSWORD
  }
});

Vue.use(VueCookies);
Vue.use(UUID);
Vue.$cache = cachedb;
Vue.$utils = utils;
Vue.component('icon', Icon)

Vue.directive('visible', function(el, binding) {
	el.style.visibility = binding.value ? 'visible' : 'hidden';
});

import '@/utils/dateTime.js'

require('@/assets/less/default.less');

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
