export default {
    getCookieDate(period) {
        let now = new Date();
        now.setTime(now.getTime() + period * 24 * 60 * 60 * 1000);
        return now.toGMTString();
    },
    toLocalTZ(utc, fmt) {
		if ((utc == '')||(utc == undefined)) {
			return '';
		}
		let date = new Date(utc+" UTC");
		return date.toChar(fmt);
	},
	toUtcTZ(lctDt, fmt) {
		if ((lctDt == '')||(lctDt == undefined)) {
			return '';
		}
		let tl = lctDt.getTime();
		tl += (lctDt.getTimezoneOffset() * 60 * 1000);
		
		let date = new Date(lctDt.toChar('yyyy-MM-ddTHH:mm:ss'));
		date.setTime(tl)
		
		return date.toChar(fmt);
	},
	checkZenKatakana(str) {
		str = (str == null) ? "" : str;
		if (str.match(/^[ァ-ヶー]+$/)) {
			return true;
		} else {
			return false;
		}
	},
	checkEmailAddressFormat(email) {
		email = (email == null) ? "" : email;
		if (email.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
			return true;
		} else {
			return false;
		}
	},
	formatTime(value) {
		let result = "";
		if ((value == "") || (value == undefined)) {
			result = ":";
		} else if (value.length < 2) {
			result = "0:0" + value;
		} else if (value.length < 3) {
			result = "0:";
			if (value.substr(0,2) < 60) {
				result = result + value.substr(0,2);
			} else {
				result = result + "00";
			}
		} else if (value.length < 4) {
			result = value.substr(0,1) + ":";
			if (value.substr(1,2) < 60) {
				result = result + value.substr(1,2);
			} else {
				result = result + "00";
			}
		} else if (value.length < 5) {
			result = value.substr(0,2);
			/*
			if (result > 23) {
				result = "23";
			}
			*/
			result = result + ":";
			if (result.substr(0,1) == "0") {
				result = result.substr(1,3);
			}
			if (value.substr(2,2) < 60) {
				result = result + value.substr(2,2);
			} else {
				result = result + "00";
			}
		}
		return result;
	},
	calcTimeRecords(data) {
		if ((data.start == ":") || (data.start == "") || (data.start == undefined)) {
			data.end = "";
			data.time1 = "";
			data.time2 = "";
			data.time3 = "";
			data.time4 = "";
			return;
		}
		if ((data.end == ":") || (data.end == "") || (data.end == undefined)) {
			data.time1 = "";
			data.time2 = "";
			data.time3 = "";
			data.time4 = "";
			return;
		}
		
		let stm = this.convertToMinutes(data.start);
		let etm = this.convertToMinutes(data.end);
		let roudou = etm - stm;
		let breaktm = 0;

		data.time2 = 0;
		data.time3 = 0;
		data.time4 = 0;

		if (roudou <= 360) {
			if ((data.breakTime != "") && (data.breakTime != undefined)) {
				breaktm = data.breakTime;
			}
		} else {
			breaktm = 1;
			if (roudou > 900) {
				breaktm = 2;
				if (roudou > 1440) {
					breaktm = 3;
				}
			}
			data.breakTime = breaktm;
		}

		let btm = breaktm * 60;
		let furikyu = this.chkFurikyu(data.comment)
		
		if (((data.holiday == 1) || (data.day == 0) || (data.day == 6)) && (!furikyu)) {
			roudou = roudou - btm;
			data.time4 = roudou / 60;
			data.time1 = "";
		} else {
			if (roudou <= 480) {
				roudou = roudou - btm;
				data.time1 = this.convertToTimeString(roudou);
			} else {
				//data.time1 = 8;
				roudou = roudou - 60;
				btm = btm - 60;
				//let zangyo = roudou - 480;
				let sinya = 0;
				if (etm > 1320) {
					// 22時以降
					sinya = (etm - 1320);
					if (btm >= 60) {
						sinya = sinya - 60;
						roudou = roudou - 60;
						//zangyo = zangyo - 60;
						btm = btm - 60;
					}
					if (etm > 1800) {
						// 30時(AM5時)以降
						sinya = 420;
						if (btm >= 60) {
							sinya = sinya - 60;
							roudou = roudou - 60;
							//zangyo = zangyo - 60;
						}
					}
					data.time3 = sinya / 60;
					roudou = roudou - sinya;
				}
				if (roudou <= 480) {
					data.time1 = roudou / 60;
				} else {
					data.time1 = 8;
					let zangyo = roudou - 480;
					data.time2 = zangyo / 60;
				}
			}
		}

		return;
	},
	convertToMinutes(tim) {
		let times = tim.split(':');
		let res = Number(times[0]) * 60 + Number(times[1]);
		return res;
	},
	convertToTimeString(tim) {
		let h = tim / 60 | 0;
		let m = tim % 60;
		
		return h + (m / 60);
	},
	checkCommentRecords(data) {
		let re = new RegExp("");
		re.compile("^([1-9]d*|0)$");

		if ((data.comment == "") || (data.comment == undefined)) {
			return;
		}
		
		let comment = "";
		let coms = data.comment.split(" ");

		let cvnFlg = false;
		for (let i = 0; i < coms.length; i++) {
			let com = coms[i];
			if (com.substr(0,1) == "#") {
				let numb = com.substr(1, com.length - 1);
				if (re.test(numb)) {
					if (!cvnFlg) {
						cvnFlg = true;
						comment = (comment == "" ? com : comment + " " + com);
					}
				} else {
					comment = (comment == "" ? com : comment + " " + com);
				}
			} else {
				comment = (comment == "" ? com : comment + " " + com);
			}
		}

		data.comment = comment;
	},
	chkFurikyu(comment) {
		let result = false;
		let re = new RegExp("");
		re.compile("^([1-9]d*|0)$");

		if ((comment == "") || (comment == undefined)) {
			return result;
		}

		let coms = comment.split(" ");
		for (let i = 0; i < coms.length; i++) {
			let com = coms[i];
			if (com.substr(0,1) == "#") {
				let numb = com.substr(1, com.length - 1);
				if (re.test(numb)) {
					if (numb == "4") {
						result = true;
						break;
					}
				}
			}
		}

		return result;
	}
}