<template>
    <div id="sideMenu" :class="{'on_menu':onmenuFlg}" @click.stop="empty_click">
        <div v-if="visibleCheck('manager')" class="menu" @click="menu_click('manager')">スタッフ一覧</div>
        <div v-if="visibleCheck('meisai')" class="menu" @click="menu_click('meisai')">給与明細</div>
        <div v-if="visibleCheck('shouyo')" class="menu" @click="menu_click('shouyo')">賞与明細</div>
        <div v-if="visibleCheck('aggregate')" class="menu" @click="menu_click('aggregate')">集計</div>
        <div v-if="visibleCheck('layout')" class="menu" @click="menu_click('layout')">レイアウト</div>
        <div v-if="visibleCheck('master')" class="menu" @click="menu_click('master')">各種マスタ管理</div>
        <div v-if="visibleCheck('logoff')" class="menu" @click="menu_click('logoff')">ログオフ</div>
    </div>
</template>

<script>
export default {
    name: "sideMenu",
    props: {
        onmenuFlg: {type:Boolean, default:false},
        managerFlg: {type:Boolean, default:false},
        runingMode: {type:String, default:""}
    },
    methods: {
        menu_click(name) {
            this.$emit('menu_click', name);
        },
        empty_click() {
            //--
        },
        visibleCheck(menu) {
            let res = true;
            
            /* switch(menu) {
                case 'logoff':
                    res = true;
                    break;
                default:
                    if (this.managerFlg) {
                        res = true;
                    }
            } */
            
            return res;
        }

    }
}
</script>

<style lang="less">
#sideMenu {
    position: fixed;
    background-color: #000;
    width: calc(100vw - 10%);
    height: 100vh;
    top: 0;
    left: calc(100vw * -1);
    transition: left 0.6s;
    z-index: 9999;
    padding: 22px;

    .menu {
        margin-bottom: 6px;
        padding: 6px 0;
        color: #fff;
        cursor: default;
        font-weight: bold;
    }
}

#sideMenu.on_menu {
    left: 0;
}

@media(min-width:500px) {
    #sideMenu {
        width: 300px;
    }
}
</style>