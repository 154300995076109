<template>
    <div class="layoutRow tblrow" @click="clickcell">
        <div class="namecell tblcell">{{item.name}}</div>
        <div class="formatcell tblcell" :class="{'officer': item.officer}">
            <div class="icon">{{format}}</div>
            <div class="icon">{{locked}}</div>
        </div>
    </div>
</template>

<script>
/**
 * レイアウト用セル コンポーネント
 */
export default {
    props: {
        item: null,
        keyName: {type:Number, default:null}
    },
    computed: {
        format() {
            let icon = "";
            if (this.item.format == "0.00") {
                icon = "0";
            } else if (this.item.format == "H:MM") {
                icon = "H";
            } else if (this.item.format == "0,000") {
                icon = "¥";
            }
            return icon
        },
        locked() {
            let icon = "";
            if (this.item.locked == 0) {
                icon = "0";
            } else if (this.item.locked == 1) {
                icon = "1";
            } else if (this.item.locked == 2) {
                icon = "2";
            }
            return icon
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        clickcell(e) {
            if (document.getElementById("sideMenu").classList.contains("on_menu")) {
                return false;
            }

            e.stopPropagation();

            this.$emit('clickcell', this.keyName);
        }
    }
}
</script>

<style lang="less">
.layoutRow {
    
    .formatcell {
        display: flex;

        .icon {
            width: 16px;
            height:16px;
            text-align: center;
            border: 1px solid #000000;
            display: inline-block;
            margin: auto;
        }

        &.officer {
            background-color: #b4b4b4;
        }
    }
}
</style>