<template>
    <div id="masterMntPg">
        <div class="main_area">
            <div class="detail">
                <button class="csv_btn" @click.stop="importSyahoCSV">社会保険料率マスタCSV取込</button>
                <div class="progress_area tbl" :class="{'visibled':showSyahoProgress}">
                    <div class="tblrow">
                        <div class="progress_bar tblcell" :style="{'background':'linear-gradient(90deg, #9ECB56 0%, #9ECB56 ' + sendProgress + '%, white ' + sendProgress + '%, white 100%)'}"></div>
                    </div>
                </div>
                <p class="comment_area">
                    全国健康保険協会の<a href="https://www.kyoukaikenpo.or.jp/g7/cat330/sb3150/" target="_brank">ホームページ</a>を開いて<br>
                    Excel[都道府県別]&nbsp;をクリックし、Excel版をダウンロード。<br>
                    ダウンロードしたExcelファイルを利用し、CSVファイルを作成する。<br>
                    県コードは"40"(福岡県)<br>
                    開始日は、適応年月の１日にする（例：令和４年３月～ → "2022-03-01"）<br>
                    社会保険額、介護保険額、年金額はそれぞれ全額分。<br>
                    介護保険額は該当全額-非該当全額の差額となる。
                </p>
            </div>
        </div>
        <input type='file' id="inportFile" @change.stop="file_change" />
    </div>
</template>

<script>
/**
 * 各種マスタメンテナンス画面
 */
import Vue from 'vue';
import encoding from 'encoding-japanese';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "masterMntPg",
    mixins: [utilityMixin],
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            headerTitle: "各種マスタ管理",
            accountId: "",
            importMode: "",
            sendProgress: 50,
            showSyahoProgress: false,
            importCSVURL: ""
        };
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }

        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;
        });
    },
    methods: {
        importSyahoCSV() {
            this.importMode = "syaho";
            document.getElementById('inportFile').click();
        },
        file_change(e){
			this.files = e.target.files || e.dataTransfer.files;
            
            switch(this.importMode) {
                case 'syaho':
                    this.showSyahoProgress = true;
                    this.importCSVURL = "master/syaho/import/";
                    break;
            }

			this.importFile(this.files);
		},
        importFile(files) {
			this.onProgress(0);
			let _self = this;
			this.readBlobAsText(files[0], function(data) {
				let blob = new Blob([data], {type: 'text/csv'});
				_self.imported(blob, files[0].name);
			}, function(per) {
				_self.onProgress(per);
			});
		},
        readBlobAsText(blob, callback, callProgress) {
			let offset = 0;
			let reader = new FileReader();
			reader.onloadend = function(e) {
				let array = new Uint8Array(reader.result);
				let dc = encoding.detect(array);
				let utf8Array = encoding.convert(array, 'UTF8', dc);
				let decoder = new TextDecoder("utf-8");
				let text = decoder.decode(new Uint8Array(utf8Array));
				callback(text);
			};
			reader.onprogress = function(e) {
				if (e.lengthComputable) {
					var percentLoaded = Math.round((e.loaded * 100) / e.total);
					callProgress(percentLoaded);
				}
			};
			reader.readAsArrayBuffer(blob);
		},
        async imported(file, filename){
			try {
				this.onProgress(0);

				let fd = new FormData();
				
				fd.append("file", file, filename);

				let onProgress = this.onProgress;

				let request = {
					timeout: 60000,
					withCredentials: true,
					headers: {
						'Accept': 'multipart/form-data; charset=utf-8',
						'Content-Type': 'multipart/form-data; charset=utf-8'
					},
					onUploadProgress(progressEvent) {
						var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						if (onProgress) onProgress(percentCompleted)
						return percentCompleted;
					}
				};

				let ress = await Vue.$cache.post(this.importCSVURL, fd, this.$store, request);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        setTimeout(() => {
                            alert("インポートしました");
                            this.sendProgress = 0;
                            this.showSyahoProgress = false;
                        }, 100);
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
			} catch(error) {
				this.isProcessing = false;
				this.sendProgress = 0;
				alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
			}
		},
		onProgress(percent) {
			this.sendProgress = percent;
		},
        base_click() {

        }
    }
}
</script>

<style lang="less">
#masterMntPg {
    width: 100%;
    height: 100vh;

    .main_area {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 62px 12% 28px;
        overflow: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .detail {
            text-align: center;

            .csv_btn {
                padding: 0 6px;
            }

            .comment_area {
                padding: 12px;
                border: 1px solid #acacac;
                border-radius: 8px;
                text-align: left;

                a {
                    text-decoration: underline;
                }
            }

            .progress_area {
                width: 70%;
                height: 3px;
                background-color: white;
                margin: 4px auto;
                visibility: hidden;
                
                .progress_bar {
                    height: 3px;
                }
            }

            .progress_area.visibled {
                visibility: visible;
            }
        }
    }
}
</style>