<template>
    <div id="managerPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <select class="typeSelect" v-model="meisaiType">
                    <option v-for="(item, idx) in meisaiTypes" :key="idx" :value="item.value">{{item.name}}</option>
                </select>
                <div class="yearMonth"><span class="btnMoveMonthPre" @click.stop="mvMonth(-1)">＜</span><span class="year">{{activeYear}}年</span><div v-if="!viewShouyo" class="month">{{activeMonth}}月分</div><div v-if="viewShouyo" class="month">{{activeSeason}}</div><span class="btnMoveMonthNext" @click.stop="mvMonth(1)">＞</span></div>
                <button class="pdf_btn" @click.stop="pdfDownload()">PDFでダウンロード</button>&nbsp;<button class="csv_btn" @click.stop="csvDownload()">CSVでダウンロード</button>
                <form class="search_form" @submit.prevent="getData">
                    <div class="search_area">
                        <svg class="search-icon" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                        </svg>
                        <input type="text" v-model="searchVal" placeholder="社員の検索"/>
                    </div>
                </form>
            </div>
            <!-- 表 -->
            <table class="list">
                <tr class="list-header">
                    <td class="pdf"><input type="checkbox" v-model="allChecked"/></td>
                    <td class="employNo">社員番号</td>
                    <td class="name">氏名</td>
                    <td class="age">年齢</td>
                    <td class="age">確定</td>
                    <td class="hireDate">入社日</td>
                    <td class="kengen">権限</td>
                    <td class="email">メールアドレス</td>
                    <td>編集</td>
                    <td>口座</td>
                    <td></td>
                </tr>
                <mngData v-for="(data) in items" :key="data.employNo" :item="data" @openMeisai="openMeisai" @openEditor="openEditor" @openBankEditor="openBankEditor"></mngData>
            </table>
            <!-- フッター -->
            <div class="footer">
                今年度の年間所定休日は{{holidaysCount}}日です。
            </div>
        </div>
    </div>
</template>

<script>
/**
 * スタッフ一覧画面
 */
import Vue from 'vue';
import iconv from 'iconv-lite';
import mngData  from '@/components/acmanagedData.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "managerPg",
    mixins: [utilityMixin],
    components: {
        'mngData': mngData
    },
    data() {
        return {
            headerTitle: "スタッフ一覧",
            items: [],
            accountId: "",
            activeYear: 0,
            activeMonth: 0,
            activeSeason: "夏季",
            allChecked: false,
            searchVal: "",
            meisaiTypes: [
                {name:"給与", value:"kyuyo"},
                {name:"賞与", value:"shouyo"}
            ],
            meisaiType: "kyuyo",
            viewShouyo: false,
            ids: [],
            holidaysCount: 999
        }
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        allChecked(newValue, oldValue) {
            this.items.forEach(function(item){
                item.checked = newValue;
            });
        },
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        },
        meisaiType(nv, ov) {
            if (nv == "kyuyo") {
                const now = new Date();
                this.activeMonth = now.getMonth() + 1;
                this.viewShouyo = false;
            } else {
                this.activeMonth = 6;
                this.activeSeason = "夏季";
                this.viewShouyo = true;
            }
            this.getData();
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }

        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;
            
            const now = new Date();

            if (this.activeYear == 0) {
                this.activeYear = now.getFullYear();
            }
            if (this.activeMonth == 0) {
                this.activeMonth = now.getMonth() + 1;
            }

            this.getInit();
        });
    },
    methods: {
        async getInit() {
            await this.getHolidayCount();
            await this.getData();
        },
        async getData() {
            try {
                this.items = [];
                
                let param = {
                    year: this.activeYear,
                    month: this.activeMonth,
                    meisaiType: this.meisaiType,
					search: this.searchVal
                };
                let ress = await Vue.$cache.post('account/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        ress.data.data.list.forEach(function(item){
                            item.checked = false;
                        });
                        this.items = ress.data.data.list;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async getHolidayCount() {
            try {
                let ress = await Vue.$cache.get('system/holidays/', undefined, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.holidaysCount = ress.data.count;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        mvMonth(m) {
            this.activeMonth = this.activeMonth + m;
            this.allChecked = false;

            if (this.meisaiType != "kyuyo") {
                if (this.activeMonth == 13) {
                    this.activeMonth = 6;
                    ++this.activeYear;
                } else if (this.activeMonth == 7) {
                    this.activeMonth = 12;
                } else if (this.activeMonth == 11) {
                    this.activeMonth = 6;
                } else if (this.activeMonth == 5) {
                    this.activeMonth = 12;
                    --this.activeYear;
                }
                if (this.activeMonth == 6) {
                    this.activeSeason = "夏季";
                } else {
                    this.activeSeason = "冬季";
                }
            } else {
                if (this.activeMonth > 12) {
                    this.activeMonth = 1;
                    ++this.activeYear;
                } else if (this.activeMonth < 1) {
                    this.activeMonth = 12;
                    --this.activeYear;
                }
            }

            this.getData();
        },
        openMeisai(uid) {
            this.$store.commit("operator_activeAccountId", uid);
            this.$store.commit("operator_activeYear", this.activeYear);
            this.$store.commit("operator_activeMonth", this.activeMonth);
            
            let m = ("" + (100 + this.activeMonth)).substring(1, 3);
            this.$router.push("/romu/meisai/" + this.meisaiType + "/" + uid + "/" + this.activeYear + m, () => {});
        },
        openEditor(uid) {
            this.$store.commit("operator_activeAccountId", uid);            
            this.$router.push("/romu/info/", () => {});
        },
        openBankEditor(uid) {
            this.$store.commit("operator_activeAccountId", uid);            
            this.$router.push("/romu/bank/", () => {});
        },
        async pdfDownload() {
            try {
                this.ids = [];
                let name = "";
                let _self = this;
                this.items.forEach(function(item){
                    if (item.checked == true) {
                        let val = {'employNo':item.employNo};
                        _self.ids.push(val);
                        if (name == "") {
                            name = item.name.replace(/\s+/g, "");
                        }
                    }
                });
                if (this.ids.length < 1) {
                    return;
                }
                let msnm = "給与";
                let tgtm = this.activeMonth+"月";
                if (this.meisaiType != "kyuyo") {
                    msnm = "賞与";
                    tgtm = this.activeSeason;
                }
                if (this.ids.length == 1) {
                    this.downloadFileName = msnm+"明細_"+this.activeYear+"年"+tgtm+"_"+name+".pdf"
                } else {
                    this.downloadFileName = msnm+"明細_"+this.activeYear+"年"+tgtm+".pdf"
                }

                let param = {
                    accountIds: this.ids,
                    year: this.activeYear,
                    month: this.activeMonth,
                    meisaiType: this.meisaiType,
                    userId: this.accountId
                };

                let request = {
					timeout: 60000,
					withCredentials: true,
					responseType: 'blob',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
                };
                
                let ress = await Vue.$cache.post('meisai/pdfs/', param, this.$store, request);
                if (ress.status == 200) {
                    const url = URL.createObjectURL(new Blob([ress.data]));

                    const link = document.createElement('a');
                    link.href = url;
					link.setAttribute('download', this.downloadFileName); //ここらへんは適当に設定する
					document.body.appendChild(link);
					link.click();
                    URL.revokeObjectURL(url);
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async csvDownload() {
            try {
                this.ids = [];
                let name = "";
                let _self = this;
                this.items.forEach(function(item){
                    if (item.checked == true) {
                        let val = {'employNo':item.employNo};
                        _self.ids.push(val);
                        if (name == "") {
                            name = item.name.replace(/\s+/g, "");
                        }
                    }
                });
                if (this.ids.length < 1) {
                    return;
                }
                let msnm = "給与";
                let tgtm = this.activeMonth+"月";
                if (this.meisaiType != "kyuyo") {
                    msnm = "賞与";
                    tgtm = this.activeSeason;
                }
                if (this.ids.length == 1) {
                    this.downloadFileName = msnm+"明細CSV_"+this.activeYear+"年"+tgtm+"_"+name+".csv"
                } else {
                    this.downloadFileName = msnm+"明細CSV_"+this.activeYear+"年"+tgtm+".csv"
                }

                let param = {
                    accountIds: this.ids,
                    year: this.activeYear,
                    month: this.activeMonth,
                    meisaiType: this.meisaiType,
                    userId: this.accountId
                };

                let request = {
					timeout: 60000,
					withCredentials: true,
					responseType: 'arraybuffer',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
                };
                
                let ress = await Vue.$cache.post('meisai/csv/', param, this.$store, request);
                if (ress.status == 200) {
                    let data = iconv.encode(Buffer.from(ress.data), 'shift-jis');
					let blob = new Blob([data], {type: 'text/csv'});
					const url = (window.URL || window.webkitURL).createObjectURL(blob);
                    
                    const link = document.createElement('a');
                    link.href = url;
					link.setAttribute('download', this.downloadFileName); //ここらへんは適当に設定する
					document.body.appendChild(link);
					link.click();
                    URL.revokeObjectURL(url);
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#managerPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .header {
            height: 42px;

            .typeSelect {
                margin-right: 18px;
            }
            
            .yearMonth {
                width: 100%;
                font-size: 1.3rem;
                font-weight: bold;
                display: inline;

                .btnMoveMonthPre {
                    margin-right: 8px;
                    cursor: pointer;
                }

                .btnMoveMonthNext {
                    margin-left: 8px;
                    cursor: pointer;
                }

                .year {
                    margin-right: 16px;
                }

                .month {
                    width: 72px;
                    text-align: right;
                    display: inline-block;
                }
            }

            .pdf_btn {
                padding: 0 6px;
                margin-left: 32px;
            }

            .csv_btn {
                padding: 0 6px;
                margin-left: 8px;
            }

            .search_form {
                margin: 0;
                display: inline;
            }

            .search_area {
                position: relative;
                width: 280px;
                float: right;
                border: 1px solid #000;
                border-radius: 17px;
                padding: 6px 12px 6px 30px;

                .search-icon {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 6px;
                    margin: auto;
                    fill: #777777;
                }
                
                input[type=text] {
                    width: 100%;
                    border: 0;

                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }
            }
        }

        .list {
            width: 100%;
            border-spacing: 0;

            .list-header {
                .pdf {
                    width: 36px;
                }
                .employNo {
                    width: 80px;
                }
                .name {
                    width: 220px;
                }
                .age {
                    width: 42px;
                }
                .kengen {
                    width: 120px;
                }
                .hireDate {
                    width: 110px;
                }
                .email {
                    width: 320px;
                }
            }

            .list-header {
                background-color: var(--gridFixedBackColor);
                font-weight: bold;
                color: white;

                td {
                    border-bottom: 1px solid white;
                    border-bottom-color: var(--gridFixedBorderColor);
                    border-left: 1px solid white;
                    border-left-color: var(--gridFixedBorderColor);
                    padding: 6px 4px;
                    text-align: center;
                    white-space: nowrap;

                    &:first-child {
                        border-left: 0;
                    }
                }
            }
        }

        .footer {
            padding: 6px 12px;
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            margin-left: 20px;
        }
    }
}

@media(max-width:1027px) {
    #managerPg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;

            .header {
                width: 1028px;
            }

            .list {
                width: 1028px;
            }

            .footer {
                width: 1028px;
            }
        }
    }
}
</style>