<template>
    <div id="meisaiPg" :class="{'syouyo':viewShouyo}">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <div class="yearMonth"><span class="btnMoveMonthPre" @click.stop="mvMonth(-1)">＜</span><span class="year">{{activeYear}}年</span><div v-if="!viewShouyo" class="month">{{activeMonth}}月分</div><div v-if="viewShouyo" class="month">{{activeSeason}}</div><span class="btnMoveMonthNext" @click.stop="mvMonth(1)">＞</span></div>
                <button v-visible="!confirmFlag" class="reset_btn" @click.stop="resetDataPre">リセット</button><button class="confirm_btn" @click.stop="confirmDataPre">{{confirmText}}</button>
                <div class="select_user"><span class="btnMoveUserPre" @click.stop="mvUser(-1)">＜</span><div class="user_name">{{name}}</div><span class="btnMoveUserNext" @click.stop="mvUser(1)">＞</span></div>
            </div>
            <!-- 給与明細 -->
            <div class="detail">
                <div class="header">
                    <div class="panel">
                        <div class="title1"><span v-if="!viewShouyo">{{title}}分</span><span v-if="viewShouyo">{{title}}</span><span class="span1">{{meisaiTitle}}</span></div>
                        <div class="companyName">{{companyName}}</div>
                        <div class="name_area"><span>氏名</span><span class="span1">（</span><span class="empNo">{{employNo}}</span><span>）</span><div class="empName">{{name}}</div><span class="ex">様</span></div>
                        <div class="position_area"><span class="title">所属</span><span class="value">{{positionName}}</span></div>
                    </div>
                    <div class="sign tbl">
                        <div class="tblrow">
                            <div class="title tblcell">受領印</div>
                        </div>
                        <div class="tblrow">
                            <div class="tblcell"></div>
                        </div>
                    </div>
                    <div class="shikyuday">支給日<div class="shikyuday_panel" @click="editDate">{{sikyuDay}}</div></div>
                </div>
                <div class="vspace"></div>
                <div class="list_panel">
                    <div class="kintai_panel detail_panel">
                        <div class="title">勤怠</div>
                        <div class="tbl">
                            <meisaiCell v-for="(item, index) in kintaiList" :item="item" :confirmFlag="confirmFlag" @editName="editNameKin" @editValue="editValueKin" :key="index" :keyName="index"></meisaiCell>
                        </div>
                        <div class="vps"></div>
                        <div class="sashihiki_panel tbl">
                            <meisaiCell :item="value1" :confirmFlag="confirmFlag" @editName="editName" @editValue="editValue" :keyName="'value1'"></meisaiCell>
                            <meisaiCell :item="value2" :confirmFlag="confirmFlag" @editName="editName" @editValue="editValue" :keyName="'value2'"></meisaiCell>
                        </div>
                    </div>
                    <div class="shikyu_panel detail_panel">
                        <div class="title">支給</div>
                        <div class="tbl">
                            <meisaiCell v-for="(item, index) in shikyuList" :item="item" :confirmFlag="confirmFlag" @editName="editNameSky" @editValue="editValueSky" :key="index" :keyName="index"></meisaiCell>
                        </div>
                    </div>
                    <div class="koujyo_panel detail_panel">
                        <div class="title">控除</div>
                        <div class="tbl">
                            <meisaiCell v-for="(item, index) in koujoList" :item="item" :confirmFlag="confirmFlag" @editName="editNameKoj" @editValue="editValueKoj" :key="index" :keyName="index"></meisaiCell>
                        </div>
                    </div>
                    <div class="etc_panel detail_panel">
                        <div class="title">その他</div>
                        <div class="tbl">
                            <meisaiCell v-for="(item, index) in otherList" :item="item" :confirmFlag="confirmFlag" @editName="editNameOth" @editValue="editValueOth" :key="index" :keyName="index"></meisaiCell>
                        </div>
                        <div class="vps"></div>
                        <div class="sashihiki_panel tbl">
                            <meisaiCell :item="value3" :confirmFlag="confirmFlag" @editName="editName" @editValue="editValue" :keyName="'value3'"></meisaiCell>
                        </div>
                        <div class="vps"></div>
                        <div class="furikomi_panel title">振込支給額</div>
                        <div class="tbl">
                            <meisaiCell v-for="(item, index) in furikomiList" :item="item" :confirmFlag="confirmFlag" @editName="editNameFri" @editValue="editValueFri" :key="index" :keyName="index"></meisaiCell>
                        </div>
                        <div class="vps"></div>
                        <div class="sashihiki_panel tbl">
                            <meisaiCell :item="value4" :confirmFlag="confirmFlag" @editName="editName" @editValue="editValue" :keyName="'value4'"></meisaiCell>
                        </div>
                        <div class="vps"></div>
                        <div class="sashihiki_panel tbl">
                            <meisaiCell :item="value5" :confirmFlag="confirmFlag" @editName="editName" @editValue="editValue" :keyName="'value5'"></meisaiCell>
                        </div>
                        <div class="vps"></div>
                        <div class="sashihiki_panel tbl">
                            <meisaiCell :item="value6" :confirmFlag="confirmFlag" @editName="editName" @editValue="editValue" :keyName="'value6'"></meisaiCell>
                            <meisaiCell :item="value7" :confirmFlag="confirmFlag" @editName="editName" @editValue="editValue" :keyName="'value7'"></meisaiCell>
                        </div>
                    </div>
                </div>
                <div class="vspace"></div>
                <div class="footer" @click="editComment">{{comment}}</div>
            </div>
            <!-- フッター -->
            <div class="footer">

            </div>
            <div v-show="editFieldFlg" ref="edit_cell" class="edit_field" @click.stop="empty"><input type="text" v-model="valEdit" @keydown="edit_keydown" @keyup="edit_keyup"/></div>
            <div v-show="editDateFieldFlg" ref="edit_date" class="edit_field" @click.stop="empty"><input type="date" v-model="valEdit" @keydown="edit_keydown" @keyup="edit_keyup"/></div>
            <div v-show="editCommentFieldFlg" ref="edit_comm" class="edit_field" @click.stop="empty"><textarea v-model="valEdit" @keydown="edit_keydown" @keyup="edit_keyup"></textarea></div>
        </div>
    </div>
</template>

<script>
/**
 * 給与明細画面
 */
import Vue from 'vue';
import meisaiCell  from '@/components/meisaiCell.vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "meisaiPg",
    mixins: [utilityMixin],
    components: {
        'meisaiCell': meisaiCell
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        editFieldFlg(nv, ov) {
            if (!nv) {
                this.saveData();
                /*
                if (this.editFullDate != "") {
                    let data = this.items.find(item => item.fullDate === this.editFullDate);
                    Vue.$utils.calcTimeRecords(data);
                    this.saveData(data);
                }
                */
            }
        },
        editDateFieldFlg(nv, ov) {
            if (!nv) {
                this.saveData();
            }
        },
        editCommentFieldFlg(nv, ov) {
            if (!nv) {
                this.saveData();
            }
        },
        confirmFlag(nv, ov) {
            if (nv) {
                this.confirmText = "取り消し";
            } else {
                this.confirmText = "確定";
            }
        },
        $route(nv, ov) {
            location.reload();
        },
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.saveFlag = false;
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            headerTitle: "給与明細",
            activeYear: 0,
            activeMonth: 0,
            activeSeason: "夏季",
            accountId: "",
            activeAccountId: "",
            meisaiType: "kyuyo",
            meisaiTitle: "給与明細書",
            companyName: "",
            title: "",
            employNo: "",
            name: "",
            positionName: "",
            kintaiList: [],
            shikyuList: [],
            koujoList: [],
            otherList: [],
            furikomiList: [],
            value1: { name: "", value: "", locked: 1 },
            value2: { name: "", value: "", locked: 1 },
            value3: { name: "", value: "", locked: 1 },
            value4: { name: "", value: "", locked: 1 },
            value5: { name: "", value: "", locked: 1 },
            value6: { name: "", value: "", locked: 2 },
            value7: { name: "", value: "", locked: 2 },
            sikyuDay: "",
            comment: "",
            staffList: [],
            valEdit: "",
            valBackup: "",
            editFieldFlg: false,
            editDateFieldFlg: false,
            editCommentFieldFlg: false,
            activeKey: "",
            activeList: "",
            saveFlag: false,
            confirmFlag: false,
            confirmText: "確定",
            meisaiTypes: [
                {name:"給与", value:"kyuyo"},
                {name:"賞与", value:"shouyo"}
            ],
            viewShouyo: false
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }

        this.$nextTick(function() {
            this.meisaiType = this.$route.params.type;

            if (this.meisaiType == "kyuyo") {
                this.$store.commit("operator_headerTitle", "給与明細");
                this.meisaiTitle = "給与明細書";
            } else {
                this.$store.commit("operator_headerTitle", "賞与明細");
                this.meisaiTitle = "賞与明細書";
            }
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$route.params.staff;
            let ym = this.$route.params.ym;
            this.activeYear = ("" + ym).substring(0, 4) - 0;
            let m = ("" + ym).substring(4, 6) - 0;
            this.activeMonth = m;

            let month = this.activeMonth;
            if (this.meisaiType != "kyuyo") {
                if (this.activeMonth < 6) {
                    month = 6;
                    this.activeSeason = "夏季";
                } else if (this.activeMonth > 6) {
                    month = 12;
                    this.activeSeason = "冬季";
                }

                if (month != this.activeMonth) {
                    let mm = ("" + (100 + month)).substring(1, 3);
                    this.$router.push("/romu/meisai/" + this.meisaiType + "/" + this.activeAccountId + "/" + this.activeYear + mm, () => {});
                    return;
                }
                this.viewShouyo = true;
            }

            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                this.clear()

                let param = {
                    "accountId": this.activeAccountId,
                    "year": this.activeYear,
                    "month": this.activeMonth,
                    "meisaiType": this.meisaiType,
                    "userId": this.accountId
                };

                let ress = await Vue.$cache.post('meisai/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.companyName = ress.data.data.companyName;
                        this.title = ress.data.data.title;
                        this.employNo = ress.data.data.employNo;
                        this.name = ress.data.data.name;
                        this.positionName = ress.data.data.positionName;
                        this.kintaiList = ress.data.data.kintaiList;
                        this.shikyuList = ress.data.data.shikyuList;
                        this.koujoList = ress.data.data.koujoList;
                        this.otherList = ress.data.data.otherList;
                        this.furikomiList = ress.data.data.furikomiList;
                        this.value1 = ress.data.data.value1;
                        this.value2 = ress.data.data.value2;
                        this.value3 = ress.data.data.value3;
                        this.value4 = ress.data.data.value4;
                        this.value5 = ress.data.data.value5;
                        this.value6 = ress.data.data.value6;
                        this.value7 = ress.data.data.value7;
                        this.sikyuDay = ress.data.data.sikyuDay;
                        this.comment = ress.data.data.comment;
                        this.staffList = ress.data.data.staffList;
                        this.confirmFlag = (ress.data.data.confirmFlag == '1') ? true : false;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }

            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async saveData() {
            if (!this.saveFlag) {
                return;
            }

            try {
                let param = {
                    "meisaiType": this.meisaiType,
                    "userId": this.accountId,
                    "accountId": this.activeAccountId,
                    "year": this.activeYear,
                    "month": this.activeMonth,
                    "kintaiList": this.kintaiList,
                    "shikyuList": this.shikyuList,
                    "koujoList": this.koujoList,
                    "furikomiList": this.furikomiList,
                    "otherList": this.otherList,
                    "value1": this.value1,
                    "value2": this.value2,
                    "value3": this.value3,
                    "value4": this.value4,
                    "value5": this.value5,
                    "value6": this.value6,
                    "value7": this.value7,
                    "sikyuDay": this.sikyuDay,
                    "comment": this.comment,
                    "getData": true
                };

                let ress = await Vue.$cache.post('meisai/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.companyName = ress.data.data.companyName;
                        this.title = ress.data.data.title;
                        this.employNo = ress.data.data.employNo;
                        this.name = ress.data.data.name;
                        this.positionName = ress.data.data.positionName;
                        this.kintaiList = ress.data.data.kintaiList;
                        this.shikyuList = ress.data.data.shikyuList;
                        this.koujoList = ress.data.data.koujoList;
                        this.otherList = ress.data.data.otherList;
                        this.furikomiList = ress.data.data.furikomiList;
                        this.value1 = ress.data.data.value1;
                        this.value2 = ress.data.data.value2;
                        this.value3 = ress.data.data.value3;
                        this.value4 = ress.data.data.value4;
                        this.value5 = ress.data.data.value5;
                        this.value6 = ress.data.data.value6;
                        this.value7 = ress.data.data.value7;
                        this.sikyuDay = ress.data.data.sikyuDay;
                        this.comment = ress.data.data.comment;
                        this.staffList = ress.data.data.staffList;
                        this.confirmFlag = (ress.data.data.confirmFlag == '1') ? true : false;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        resetDataPre() {
            if (!confirm("初期化しますか？")) {
                return false;
            }
            this.resetData();
        },
        async resetData() {
            try {
                this.clear()

                let param = {
                    "accountId": this.activeAccountId,
                    "year": this.activeYear,
                    "month": this.activeMonth,
                    "meisaiType": this.meisaiType,
                    "userId": this.accountId
                };

                let ress = await Vue.$cache.post('meisai/reset/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.companyName = ress.data.data.companyName;
                        this.title = ress.data.data.title;
                        this.employNo = ress.data.data.employNo;
                        this.name = ress.data.data.name;
                        this.positionName = ress.data.data.positionName;
                        this.kintaiList = ress.data.data.kintaiList;
                        this.shikyuList = ress.data.data.shikyuList;
                        this.koujoList = ress.data.data.koujoList;
                        this.otherList = ress.data.data.otherList;
                        this.furikomiList = ress.data.data.furikomiList;
                        this.value1 = ress.data.data.value1;
                        this.value2 = ress.data.data.value2;
                        this.value3 = ress.data.data.value3;
                        this.value4 = ress.data.data.value4;
                        this.value5 = ress.data.data.value5;
                        this.value6 = ress.data.data.value6;
                        this.value7 = ress.data.data.value7;
                        this.sikyuDay = ress.data.data.sikyuDay;
                        this.comment = ress.data.data.comment;
                        this.staffList = ress.data.data.staffList;
                        this.confirmFlag = (ress.data.data.confirmFlag == "1") ? true : false;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }

            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        confirmDataPre() {
            if (this.confirmFlag) {
                if (!confirm("確定を取り消しますか？")) {
                    return;
                }
            } else {
                if (!confirm("確定しますか？")) {
                    return;
                }
            }
            this.confirmFlag = !this.confirmFlag;
            this.confirmData();
        },
        async confirmData() {
            try {
                let param = {
                    "accountId": this.activeAccountId,
                    "year": this.activeYear,
                    "month": this.activeMonth,
                    "meisaiType": this.meisaiType,
                    "userId": this.accountId,
                    "confirmFlag": (this.confirmFlag) ? "1" : "0"
                };

                let ress = await Vue.$cache.post('meisai/confirm/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        //--
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error+" /param:"+JSON.stringify(param);
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        mvMonth(m) {
            let year = this.activeYear;
            let month = this.activeMonth + m;
            if (this.meisaiType != "kyuyo") {
                // 賞与
                if (month == 13) {
                    month = 6;
                    ++year;
                } else if (month == 7) {
                    month = 12;
                } else if (month == 11) {
                    month = 6;
                } else if (month == 5) {
                    month = 12;
                    --year;
                }
            } else {
                // 給与
                if (month > 12) {
                    month = 1;
                    ++year;
                } else if (month < 1) {
                    month = 12;
                    --year;
                }
            }

            let mm = ("" + (100 + month)).substring(1, 3);
            this.$router.push("/romu/meisai/" + this.meisaiType + "/" + this.activeAccountId + "/" + year + mm, () => {});
        },
        mvUser(m) {
            let uid = this.activeAccountId;
            let flg = 0
            if (m > 0) {
                for (let p = 0; p < this.staffList.length; p++) {
                    if (this.staffList[p] == uid) {
                        flg = 1;
                        continue;
                    }
                    if (flg) {
                        uid = this.staffList[p];
                        flg = 0;
                        break;
                    }
                }
            } else {
                for (let p = this.staffList.length-1; p > -1; p--) {
                    if (this.staffList[p] == uid) {
                        flg = 1;
                        continue;
                    }
                    if (flg) {
                        uid = this.staffList[p];
                        flg = 0;
                        break;
                    }
                }
            }
            if (flg) {
                if (m > 0) {
                    uid = this.staffList[0];
                } else {
                    uid = this.staffList[this.staffList.length - 1];
                }
            }

            let year = this.activeYear;
            let month = this.activeMonth;
            let mm = ("" + (100 + month)).substring(1, 3);
            this.$router.push("/romu/meisai/" + this.meisaiType + "/" + uid + "/" + year + mm, () => {});
        },
        clear() {
            this.companyName = "";
            this.title = "";
            this.employNo = "";
            this.name = "";
            this.positionName = "";
            this.kintaiList = [];
            this.shikyuList = [];
            this.koujoList = [];
            this.otherList = [];
            this.furikomiList = [];
            this.value1 = { name: "", value: "", locked: 1 };
            this.value2 = { name: "", value: "", locked: 1 };
            this.value3 = { name: "", value: "", locked: 1 };
            this.value4 = { name: "", value: "", locked: 1 };
            this.value5 = { name: "", value: "", locked: 1 };
            this.value6 = { name: "", value: "", locked: 2 };
            this.value7 = { name: "", value: "", locked: 2 };
            this.sikyuDay = "";
            this.comment = "";
        },
        editNameKin(key, target) {
            this.valEdit = this.kintaiList[key].name;
            this.editEx("text", key, target, "name", "kintaiList");
        },
        editValueKin(key, target) {
            this.valEdit = this.kintaiList[key].value;
            this.editEx("text", key, target, "value", "kintaiList");
        },
        editNameSky(key, target) {
            this.valEdit = this.shikyuList[key].name;
            this.editEx("text", key, target, "name", "shikyuList");
        },
        editValueSky(key, target) {
            this.valEdit = this.shikyuList[key].value;
            this.editEx("text", key, target, "value", "shikyuList");
        },
        editNameKoj(key, target) {
            this.valEdit = this.koujoList[key].name;
            this.editEx("text", key, target, "name", "koujoList");
        },
        editValueKoj(key, target) {
            this.valEdit = this.koujoList[key].value;
            this.editEx("text", key, target, "value", "koujoList");
        },
        editNameOth(key, target) {
            this.valEdit = this.otherList[key].name;
            this.editEx("text", key, target, "name", "otherList");
        },
        editValueOth(key, target) {
            this.valEdit = this.otherList[key].value;
            this.editEx("text", key, target, "value", "otherList");
        },
        editNameFri(key, target) {
            this.valEdit = this.furikomiList[key].name;
            this.editEx("text", key, target, "name", "furikomiList");
        },
        editValueFri(key, target) {
            this.valEdit = this.furikomiList[key].value;
            this.editEx("text", key, target, "value", "furikomiList");
        },
        editName(key, target) {
            this.valEdit = this[key].name;
            this.editEx("text", key, target, "name", "");
        },
        editValue(key, target) {
            this.valEdit = this[key].value;
            this.editEx("text", key, target, "value", "");
        },
        editDate(e) {
            if (document.getElementById("sideMenu").classList.contains("on_menu")) {
                return false;
            }

            e.stopPropagation();

            let datetx = this.sikyuDay;
            let date = new Date();
            let date2 = date.convertWarekiToSeireki(datetx);
            this.valEdit = date2.toChar("yyyy-MM-dd");
            this.editEx("date", "sikyuDay", e.target, "value", "");

            return false;
        },
        editComment(e) {
            if (document.getElementById("sideMenu").classList.contains("on_menu")) {
                return false;
            }
            if (this.confirmFlag) {
                return false;
            }

            e.stopPropagation();

            this.valEdit = this.comment;
            this.editEx("comment", "comment", e.target, "value", "");
        },
        editEx(type, key, target, node, lstNm) {
            console.log("d");
            this.valBackup = this.valEdit;
            this.activeKey = key;
            this.activeList = lstNm;

            let elm = null;
            if (type == "text") {
                elm = this.$refs.edit_cell;
            } else if (type == "date") {
                elm = this.$refs.edit_date;
            } else if (type == "comment") {
                elm = this.$refs.edit_comm;
            }

            let area = document.querySelector(".main_area");
            let rect = target.getBoundingClientRect();
            
            elm.style.width = target.clientWidth + "px";
            elm.style.height = target.clientHeight + 2 + "px";
            elm.style.top = rect.top + document.documentElement.scrollTop - 1 + "px";
            elm.style.left = rect.left + area.scrollLeft + "px";

            if (node == "value") {
                elm.classList.remove("title");
                elm.classList.add("value");
            } else {
                elm.classList.remove("value");
                elm.classList.add("title");
            }
            
            if (type == "text") {
                this.editFieldFlg = true;
                this.editDateFieldFlg = false;
                this.editCommentFieldFlg = false;
            } else if (type == "date") {
                this.editDateFieldFlg = true;
                this.editFieldFlg = false;
                this.editCommentFieldFlg = false;
            } else if (type == "comment") {
                elm.style.top = rect.top + document.documentElement.scrollTop + 6 - 1 + "px";
                this.editCommentFieldFlg = true;
                this.editFieldFlg = false;
                this.editDateFieldFlg = false;
            }

            this.saveFlag = true;

            let _type = type
            setTimeout(function(){
                let inp = elm.getElementsByTagName("input")[0];
                if (_type == "text") {
                    inp.setSelectionRange(0, 10);
                } else if (_type == "comment") {
                    inp = elm.getElementsByTagName("textarea")[0];
                    inp.setSelectionRange(0, 10);
                }
                inp.focus();
            }, 100);
        },
        edit_keydown(e) {
            //--
        },
        edit_keyup(e) {
            if (e.keyCode == 27) {
                this.base_click();
                if (this.activeList == "") {
                    if (e.currentTarget.parentNode.classList.contains("title")) {
                        this[this.activeKey].name = this.valBackup;
                    } else {
                        if (this.activeKey == "sikyuDay") {
                            let val = e.currentTarget.value;
                            if (e.currentTarget.value != "") {
                                let date = new Date(this.valBackup);
                                val = date.toChar("GG y年 M月 d日");
                            }
                            this[this.activeKey] = val;
                        } else if (this.activeKey == "comment") {
                            this[this.activeKey] = this.valBackup;
                        } else {
                            this[this.activeKey].value = this.valBackup;
                        }
                    }
                } else {
                    if (e.currentTarget.parentNode.classList.contains("title")) {
                        this[this.activeList][this.activeKey].name = this.valBackup;
                    } else {
                        this[this.activeList][this.activeKey].value = this.valBackup;
                    }
                }
                this.saveFlag = false;
                return;
            } else if ((e.keyCode == 13) || (e.keyCode == 9)) {
                if (this.editFieldFlg) { this.editFieldFlg = false; }
                if (this.editDateFieldFlg) { this.editDateFieldFlg = false; }
                if (this.editCommentFieldFlg) { this.editCommentFieldFlg = false; }
                return;
            }

            if (this.activeList == "") {
                if (e.currentTarget.parentNode.classList.contains("title")) {
                    this[this.activeKey].name = e.currentTarget.value;
                } else {
                    if (this.activeKey == "sikyuDay") {
                        let val = e.currentTarget.value;
                        if (e.currentTarget.value != "") {
                            let date = new Date(e.currentTarget.value);
                            val = date.toChar("GG y年 M月 d日");
                        }
                        this[this.activeKey] = val;
                    } else if (this.activeKey == "comment") {
                        this[this.activeKey] = e.currentTarget.value;
                    } else {
                        this[this.activeKey].value = e.currentTarget.value;
                    }
                }
            } else {
                if (e.currentTarget.parentNode.classList.contains("title")) {
                    this[this.activeList][this.activeKey].name = e.currentTarget.value;
                } else {
                    this[this.activeList][this.activeKey].value = e.currentTarget.value;
                }
            }
        },
        base_click() {
            if (this.editFieldFlg) { this.editFieldFlg = false; }
            if (this.editDateFieldFlg) { this.editDateFieldFlg = false; }
            if (this.editCommentFieldFlg) { this.editCommentFieldFlg = false; }
            this.valEdit = "";
        },
        empty() {

        }
    }
}
</script>
# w 717 / h 504
# w 701.64  /  h 477.01  x 1.3
<style lang="less">
&:root {
    --borderColor: #8EA9DB;
    --backgroundColor: #D9E1F2;
    --color: #305496;
}

#meisaiPg {
    width: 100%;

    &.syouyo {
        --borderColor: #A9D08E;
        --backgroundColor: #E2EFDA;
        --color: #548235;
    }

    .main_area {
        position: relative;
        width: 100%;
        padding: 62px 12% 28px;
        overflow: auto;

        .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0 0 8px;

            .typeSelect {
                margin-right: 18px;
            }

            .yearMonth {
                width: 240px;
                height: 30px;
                font-size: 1.3rem;
                font-weight: bold;

                .btnMoveMonthPre {
                    margin-right: 8px;
                    user-select: none;
                    cursor: pointer;
                }

                .btnMoveMonthNext {
                    margin-left: 8px;
                    user-select: none;
                    cursor: pointer;
                }

                .year {
                    margin-right: 16px;
                }

                .month {
                    width: 72px;
                    text-align: right;
                    display: inline-block;
                }
            }

            .reset_btn {
                width: 90px;
                padding: 0 12px;
            }

            .confirm_btn {
                width: 90px;
                padding: 0 12px;
            }

            .select_user {
                width: 240px;
                height: 30px;
                font-size: 1.3rem;
                font-weight: bold;
                text-align: right;

                .btnMoveUserPre {
                    margin-right: 8px;
                    user-select: none;
                    cursor: pointer;
                }

                .btnMoveUserNext {
                    margin-left: 8px;
                    margin-right: 8px;
                    user-select: none;
                    cursor: pointer;
                }

                .user_name {
                    width: 160px;
                    text-align: center;
                    display: inline-block;
                }
            }
        }

        .detail {
            font-size: 10.5pt;
            background-color: #ffffff;
            color: #000000;
            margin-left: calc((100% - 912.13px) / 2);
            width: 912.13px;
            height: 620.11px;
            border: 1px solid var(--borderColor);

            .header {
                position: relative;
                height: 116.9px;

                .panel {
                    width: 428.71px;
                    height: 116.89px;
                    border-right: 1px solid var(--borderColor);
                    border-bottom: 1px solid var(--borderColor);

                    .title1 {
                        display: inline-block;
                        font-size: 14pt;
                        font-weight: bold;
                        padding-left: 40px;
                        margin-top: 12px;

                        .span1 {
                            margin-left: 70px;
                        }
                    }

                    .companyName {
                        margin-top: 12px;
                        margin-left: 20px;
                    }

                    .name_area, .position_area {
                        margin-left: 20px;
                    }

                    .name_area {
                        .span1 {
                            padding-left: 4px;
                        }

                        .empNo {
                            padding: 0 12px;
                        }

                        .empName {
                            display: inline-block;
                            width: 160px;
                            margin-left: 12px;
                        }

                        .ex {
                            text-align: right;
                        }
                    }

                    .position_area {
                        .value {
                            padding-left: 12px;
                        }
                    }
                }

                .sign {
                    width: 80.15px;
                    height: 95.97px;
                    border-left: 1px solid var(--borderColor);
                    border-bottom: 1px solid var(--borderColor);

                    .title {
                        text-align: center;
                        vertical-align: middle;
                        height: 21px;
                        background-color: var(--backgroundColor);
                        border-bottom: 1px solid var(--borderColor);
                        color: var(--color);
                        font-size: 9pt;
                    }
                }

                .shikyuday {
                    position: absolute;
                    left: 500px;
                    bottom: 0;
                    border-bottom: 1px solid var(--borderColor);
                    width: 240px;
                    padding: 0 20px;

                    .shikyuday_panel {
                        display: inline-block;
                        width: 140px;
                        text-align: center;
                        margin-left: 16px;
                    }
                }
            }

            .list_panel {
                height: 418.3px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                position: relative;

                .kintai_panel {
                    width: 187px;

                    .title {
                        border-right: 1px solid var(--borderColor);
                    }

                    .tbl {

                        .tblcell {
                            border-right: 1px solid var(--borderColor);
                        }

                        .namecell {
                            width: 114px;
                        }
                    }
                }

                .shikyu_panel, .koujyo_panel, .etc_panel {
                    width: 230.26px;

                    .title {
                        border-left: 1px solid var(--borderColor);
                    }

                    .tbl {
                        
                        .tblcell {
                            &:first-child {
                                border-left: 1px solid var(--borderColor);
                            }
                        }

                        .namecell {
                            width: 127.22px;
                        }
                    }
                }

                .shikyu_panel, .koujyo_panel {
                    .title {
                        border-right: 1px solid var(--borderColor);
                    }

                    .tbl {
                        
                        .tblcell {
                            border-right: 1px solid var(--borderColor);
                        }
                    }
                }

                .sashihiki_panel {
                    border-top: 1px solid var(--borderColor);
                }

                .detail_panel {
                    font-size: 9pt;

                    .title {
                        text-align: center;
                        border-top: 1px solid var(--borderColor);
                        border-bottom: 1px solid var(--borderColor);
                        background-color: var(--backgroundColor);
                        height: 21px;
                        color: var(--color);
                        letter-spacing: 12px;
                        padding-left: 12px;
                    }

                    .tbl {
                        width: 100%;

                        .tblcell {
                            border-bottom: 1px solid var(--borderColor);
                            padding: 0 4px;
                            height: 21px;
                            vertical-align: middle;

                            &:first-child {
                                border-right: 1px solid var(--borderColor);
                            }
                        }

                        .namecell {
                            background-color: var(--backgroundColor);
                            color: var(--color);
                        }

                        .valuecell {
                            text-align: right;
                        }
                    }
                }

                .vps {
                    height: 20px;
                }
            }

            .footer {
                height: 50.92px;
                padding: 6px;
                border-top: 1px solid var(--borderColor);
            }

            .vspace {
                height: 11px;
            }
        }
    }

    .edit_field {
        position: absolute;
        background-color: #FFFFFF;
        box-shadow: 1px 1px 6px 2px rgba(0,0,0,0.2);
        color: #000000;
        font-size: 9pt;
        
        input[type="text"], input[type="date"] {
            width: 100%;
            height: 100%;
            border: 0;
            padding: 2px 6px;
            background-color: #FFFFFF;
            color: #000000;
            font-size: 9pt;
            
            &:focus {
                border: 0;
                outline: 0;
            }
        }

        &.title {
            background-color: var(--backgroundColor);

            input[type="text"] {
                background-color: var(--backgroundColor);
                color: var(--color);
            }
        }

        textarea {
            width: 100%;
            height: 100%;
            border: 0;
            padding: 2px 6px;
            background-color: #FFFFFF;
            color: #000000;
            font-size: 10.5pt;
            
            &:focus {
                border: 0;
                outline: 0;
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            margin-left: 20px;
        }
    }
}

@media(max-width:1027px) {
    #meisaiPg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;
        }
    }
}

</style>