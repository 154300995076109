<template>
    <div id="aggregatePg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <div class="year_area"><span class="btnMoveYearPre" @click.stop="mvYear(-1)">＜</span><span class="year">{{activeYear}}年度</span><span class="btnMoveYearNext" @click.stop="mvYear(1)">＞</span></div>
                <div class="tab" :class="{'active':activeTab=='list1'}" @click.stop="tab_click('list1')">人件費一覧</div><div class="tab" :class="{'active':activeTab=='list2'}" @click.stop="tab_click('list2')">人件費一覧</div>
                <button class="csv_btn" @click.stop="csvDownload">CSVでダウンロード</button>
            </div>
            <!-- メイン -->
            <div class="detail">
                <!-- リスト1 -->
                <div v-if="activeTab=='list1'" class="list1">
                    <table>
                        <tr class="header_row">
                            <td v-for="(dd, ix) in header" :key="ix">{{dd}}</td>
                        </tr>
                        <tr class="list_row" v-for="(item, idx) in data.list" :key="idx">
                            <td v-for="(itm, ix) in item" :key="ix">{{itm}}</td>
                        </tr>
                    </table>
                </div>
                <!-- リスト2 -->
                <div v-if="activeTab=='list2'">

                </div>
            </div>
            <!-- フッター -->
            <div class="footer">

            </div>
        </div>
    </div>
</template>

<script>
/**
 * 集計画面
 */
import Vue from 'vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "aggregatePg",
    mixins: [utilityMixin],
    data() {
        return {
            headerTitle: "集計",
            activeYear: 2021,
            activeTab: "",
            data: {},
            header: []
        }
    },
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }

        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.header.push("氏　名");
            for (let m = 1; m < 13; m++) {
                let mon = m + 5;
                if (mon > 12) {
                    mon = mon - 12;
                }
                this.header.push(mon+"月");
            }
            this.header.push("合　計");

            let date = new Date();
            let y = date.getFullYear();
            if (date.getMonth() < 5) {
                y -= 1;
            }
            this.activeYear = y;
            this.activeTab = "list1";

            this.getData();
        });
    },
    methods: {
        async getData() {
            try {
                let param = {
                    "nendo": this.activeYear
                }
                let uri = "aggregate/" + this.activeTab + "/";
                let ress = await Vue.$cache.post(uri, param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.data = ress.data.data;
                        if (this.activeTab == "list1") {
                            //--
                        } else if (this.activeTab == "list2") {
                            //--
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async csvDownload() {
            try {
                let param = {
                    "nendo": this.activeYear
                }

                let request = {
					timeout: 60000,
					withCredentials: true,
					responseType: 'blob',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
                };
                let downloadFileName = this.activeTab + ".csv";
                let uri = "aggregate/csv/" + this.activeTab + "/";
                let ress = await Vue.$cache.post(uri, param, this.$store, request);
                if (ress.status == 200) {
                    let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
					let blob = new Blob([bom, ress.data], {type: 'text/csv'});
					const url = (window.URL || window.webkitURL).createObjectURL(blob);
                    
                    const link = document.createElement('a');
                    link.href = url;
					link.setAttribute('download', downloadFileName); //ここらへんは適当に設定する
					document.body.appendChild(link);
					link.click();
                    URL.revokeObjectURL(url);
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        mvYear(y) {
            this.activeYear += y;
            this.getData();
        },
        tab_click(tab) {
            if (this.activeTab == tab) {
                return;
            }

            this.activeTab = tab;
            this.getData();
        },
        base_click() {

        }
    }
}
</script>

<style lang="less">
#aggregatePg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 62px 12% 28px;
        overflow: auto;

        .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0 0 8px;

            .year_area {
                width: 240px;
                height: 30px;
                font-size: 1.3rem;
                font-weight: bold;

                .btnMoveYearPre {
                    margin-right: 8px;
                    user-select: none;
                    cursor: pointer;
                }

                .btnMoveYearNext {
                    margin-left: 8px;
                    user-select: none;
                    cursor: pointer;
                }
            }

            .tab {
                cursor: pointer;
                width: 130px;
                padding: 2px 8px;
                text-align: center;
                border-radius: 6px;
                background-color: #4a4a4a;

                &.active {
                    background-color: #7a7a7a;
                    box-shadow: inset 1px 1px 2px #303030,
                                inset -1px -1px 2px #4a4a4a;
                }
            }

            .csv_btn {
                padding: 0 6px;
                margin-left: 8px;
            }
        }

        .detail {
            width: 1102px;
            height: calc(100vh - 140px);
            overflow: auto;
            border-bottom: 1px solid gray;

            .list1 {
                width: 1102px;

                table {
                    border-spacing: 0;
                    border-collapse: collapse;
                    font-size: 0.86rem;

                    .header_row, .list_row {
                        td {
                            width: 72px;
                            padding: 2px 6px;
                            border: 1px solid gray;

                            &:first-child {
                                width: 130px;
                            }
                        }
                    }

                    .header_row {
                        td {
                            position: sticky;
                            top: 0;
                            text-align: center;
                            background-color: #4f4f4f;
                            
                            &::before {
                                content: "";
                                position: absolute;
                                top: -1px;
                                left: -1px;
                                width: 100%;
                                height: 100%;
                                border: 1px solid gray;
                                box-sizing: content-box;
                            }
                        }
                    }

                    .list_row {
                        td {
                            text-align: right;

                            &:first-child {
                                text-align: left;
                                background-color: #4f4f4f;
                            }
                        }
                    }

                    &:last-child {
                        td {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            margin-left: 20px;
        }
    }
}

@media(max-width:1027px) {
    #aggregatePg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;
        }
    }
}
</style>