import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index.vue'
import Splash from '@/views/splash.vue'
import LoginPg from '@/views/login.vue'
import ManagerPg from '@/views/manager.vue'
import MeisaiPg from '@/views/meisai.vue'
import RomuInfoPg from '@/views/romuInfo.vue'
import RomuBankPg from '@/views/romuBank.vue'
import AggregatePg from '@/views/aggregate.vue'
import LayoutEdtPg from '@/views/layoutEditor.vue'
import MasterMntPg from '@/views/masterMnt.vue'
import TrialPg from '@/views/trial.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/romu',
    component: Index,
    children: [
      {
        path: '',
        name: 'managerPg',
        component: ManagerPg
      },
      {
        path: 'meisai/:type/:staff/:ym',
        name: 'meisaiPg',
        component: MeisaiPg
      },
      {
        path: 'info',
        name: 'romuInfoPg',
        component: RomuInfoPg
      },
      {
        path: 'bank',
        name: 'romuBankPg',
        component: RomuBankPg
      },
      {
        path: 'aggregate',
        name: 'aggregatePg',
        component: AggregatePg
      },
      {
        path: 'layout',
        name: 'layoutEditorPg',
        component: LayoutEdtPg
      },
      {
        path: 'master',
        name: 'masterMntPg',
        component: MasterMntPg
      },
      {
        path: 'trial',
        name: 'trialPg',
        component: TrialPg
      }
    ]
  },
  {
    path: '/',
    name: 'splash',
    component: Splash
  },
  {
    path: '/login',
    name: 'loginPg',
    component: LoginPg
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
