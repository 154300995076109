<template>
    <div id="romuBankPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <div class="staffName">{{staffName}}</div>
            </div>
            <!-- メイン -->
            <div class="main">
                <div class="rekilist_area">
                    <div class="header_area">履歴</div>
                    <div class="reki_item" v-for="(itm, idx) in rekilist" :key="idx" @click.stop="openInfo(itm.startDate)">{{itm.startDate}}</div>
                </div>
                <div class="detail_area">
                    <div class="detail_kikan"><div class="first_title">開始日：</div><div><input type="date" v-model="item.startDate" @blur="sdtFocusOut" @keyup="edit_keyup"/></div><span class="delim">〜</span><span>終了日：</span><div><input type="date" v-model="item.endDate" /></div></div>
                    <div class="detailPanel tbl">
                        <div class="tblrow">
                            <div class="name tblcell">金融機関：</div>
                            <div class="value tblcell"><div class="code">{{ item.bankNumber }}</div><button @click.stop="openBank()">...</button><div class="bname">{{ bankName }}</div></div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">支店：</div>
                            <div class="value tblcell"><div class="code">{{ item.branchNumber }}</div><button @click.stop="openBranch()">...</button><div class="bname">{{ branchName }}</div></div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">預金種目：</div>
                            <div class="value tblcell">
                                <select class="accountType" v-model="item.accountType">
                                    <option v-for="(itm, idx) in accountTypes" :key="idx" :value="itm.value">{{itm.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">口座番号：</div>
                            <div class="value tblcell"><input type="text" v-model="item.accountNumber" /></div>
                        </div>
                    </div>
                    <div class="buttons_area">
                        <button class="send" @click.stop="saveData">登録</button><button class="delete" @click.stop="deleteData">削除</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="bankWindow" v-if="showBank">
            <div class="header_area">
                <div class="close-win" @click="close_win">✕</div>
            </div>
            <div class="detail_area">
                <div v-if="!lockBankNumber" class="tbl">
                    <div class="tblrow">
                        <div class="tblcell">
                            <form class="search_form" @submit.prevent="getBankList">
                                <div class="search_area">
                                    <svg class="search-icon" viewBox="0 0 24 24" width="24" height="24">
                                        <path d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                                    </svg>
                                    <input type="text" v-model="searchVal" placeholder="金融機関の検索"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div v-if="!lockBankNumber" class="banklist_area">
                    <div class="tbl">
                        <div class="tblrow" v-for="(item) in bankList" :key="item.bankNumber">
                            <div class="tblcell" :class="{'selected': item.selected}" @click.stop="setBankNumber(item.bankNumber, item.name)">{{item.bankNumber}}</div>
                            <div class="tblcell" :class="{'selected': item.selected}" @click.stop="setBankNumber(item.bankNumber, item.name)">{{item.name}}</div>
                            <div class="tblcell" :class="{'selected': item.selected}" @click.stop="setBankNumber(item.bankNumber, item.name)">{{item.kana}}</div>
                        </div>
                    </div>
                </div>
                <div v-if="!lockBankNumber" class="tbl">
                    <div class="tblrow">
                        <div class="tblcell">支店一覧</div>
                    </div>
                </div>
                <div v-if="lockBankNumber" class="tbl">
                    <div class="tblrow">
                        <div class="tblcell title">金融機関：</div>
                        <div class="tblcell">{{ selectBankName }}</div>
                    </div>
                </div>
                <div class="branchlist_area">
                    <div class="tbl">
                        <div class="tblrow" v-for="(item) in branchList" :key="item.branchNumber">
                            <div class="tblcell" :class="{'selected': item.selected}" @click.stop="setBranchNumber(item.branchNumber, item.name)">{{item.branchNumber}}</div>
                            <div class="tblcell" :class="{'selected': item.selected}" @click.stop="setBranchNumber(item.branchNumber, item.name)">{{item.name}}</div>
                            <div class="tblcell" :class="{'selected': item.selected}" @click.stop="setBranchNumber(item.branchNumber, item.name)">{{item.kana}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_area">
                <button class="btnOk" @click.stop="editorOk">決定</button>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * 個別労務情報管理画面
 */
import Vue from 'vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "romuBankPg",
    mixins: [utilityMixin],
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        activeStartDate(nv, ov) {
            if (this.activeStartDate != "") {
                this.getData();
            }
        },
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            headerTitle: "振込口座情報",
            accountId: "",
            activeAccountId: "",
            activeStartDate: "",
            staffName: "",
            bankName: "",
            branchName: "",
            accountTypes: [],
            rekilist: [],
            item: {},
            showBank: false,
            lockBankNumber: false,
            selectBankNumber: "",
            selectBankName: "",
            selectBranchNumber: "",
            selectBranchName: "",
            searchVal: "",
            bankList: [],
            branchList: []
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }

        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;

            this.getRekiList()
        });
    },
    methods: {
        async getRekiList() {
            try {
                this.rekilist = [];
                
                let param = {
					staffId: this.activeAccountId
                };
                let ress = await Vue.$cache.post('bank/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.staffName = ress.data.data.name;
                        this.accountTypes = ress.data.data.accountTypes;
                        this.rekilist = ress.data.data.list;
                        if (this.rekilist.length > 0) {
                            this.activeStartDate = this.rekilist[0].startDate;
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async getData() {
            try {
                let param = {
					staffId: this.activeAccountId,
                    startDate: this.activeStartDate,
                    userId: this.accountId
                };
                let ress = await Vue.$cache.post('bank/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.item = ress.data.data;
                        this.bankName = this.item.bankName;
                        this.branchName = this.item.branchName;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        saveData() {
            if (!confirm("登録しますか？")) {
                return false;
            }
            this.saveDataEx();
        },
        async saveDataEx() {
            try {
                let param = {
					staffId: this.activeAccountId,
                    startDate: this.item.startDate,
                    endDate: this.item.endDate,
                    userId: this.accountId,
                    bankData: this.item
                };
                let ress = await Vue.$cache.post('bank/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.activeStartDate = "";
                        this.getRekiList();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        deleteData() {
            if (!confirm("削除しますか？")) {
                return false;
            }
            this.deleteDataEx();
        },
        async deleteDataEx() {
            try {
                let param = {
					staffId: this.activeAccountId,
                    startDate: this.item.startDate,
                    userId: this.accountId
                };
                let ress = await Vue.$cache.post('bank/delete/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.activeStartDate = "";
                        this.getRekiList();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async getBankList() {
            try {
                let param = {
					cond: this.searchVal,
                    startDate: this.item.startDate
                };
                let ress = await Vue.$cache.post('bank/search/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.bankList = ress.data.data.list;
                        for (let i = 0; i < this.bankList.length - 1; i++) {
                            let item = this.bankList[i];
                            item.selected = false;
                            if (item.bankNumber == this.selectBankNumber) {
                                item.selected = true;
                            }
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async getBranchList() {
            try {
                let param = {
					banknumber: this.selectBankNumber,
                    startDate: this.item.startDate
                };
                let ress = await Vue.$cache.post('bank/search/branch/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.branchList = ress.data.data.list;
                        for (let i = 0; i < this.branchList.length - 1; i++) {
                            let item = this.branchList[i];
                            item.selected = false;
                            if (item.branchNumber == this.selectBranchNumber) {
                                item.selected = true;
                            }
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        openInfo(startDate) {
            this.activeStartDate = startDate;
        },
        openBank() {
            this.searchVal = "";
            this.bankList = [];
            this.branchList = [];
            this.selectBankNumber = this.item.bankNumber;
            this.selectBankName = this.item.bankName;
            this.selectBranchNumber = this.item.branchNumber;
            this.selectBranchName = this.item.branchName;
            this.lockBankNumber = false;
            this.showBank = true;
        },
        openBranch() {
            this.searchVal = "";
            this.bankList = [];
            this.branchList = [];
            this.selectBankNumber = this.item.bankNumber;
            this.selectBankName = this.item.bankName;
            this.selectBranchNumber = this.item.branchNumber;
            this.selectBranchName = this.item.branchName;
            this.lockBankNumber = true;
            this.showBank = true;

            this.getBranchList();
        },
        close_win() {
            this.showBank = false;
            this.lockBankNumber = false;
            this.selectBankNumber = "";
            this.selectBankName = "";
            this.selectBranchNumber = "";
            this.selectBranchName = "";
        },
        editorOk() {
            if (!this.lockBankNumber) {
                this.$set(this.item, 'bankNumber', this.selectBankNumber);
                this.$set(this.item, 'bankName', this.selectBankName);
                this.bankName = this.selectBankName;
            }
            this.$set(this.item, 'branchNumber', this.selectBranchNumber);
            this.$set(this.item, 'branchName', this.selectBranchName);
            this.branchName = this.selectBranchName;

            this.lockBankNumber = false;
            this.showBank = false;
        },
        setBankNumber(bankNumber, name) {
            this.selectBankNumber = bankNumber;
            this.selectBankName = name;

            for (let i = 0; i < this.bankList.length - 1; i++) {
                let item = this.bankList[i];
                item.selected = false;
                if (item.bankNumber == this.selectBankNumber) {
                    item.selected = true;
                }
            }
            
            this.getBranchList();
        },
        setBranchNumber(branchNumber, name) {
            this.selectBranchNumber = branchNumber;
            this.selectBranchName = name;

            let list = JSON.parse(JSON.stringify(this.branchList))
            for (let i = 0; i < list.length - 1; i++) {
                let item = list[i];
                item.selected = false;
                if (item.branchNumber == this.selectBranchNumber) {
                    item.selected = true;
                }
            }

            this.branchList = list;
        },
        edit_keyup(e) {
            if ((e.keyCode == 13) || (e.keyCode == 9)) {
                if (this.activeStartDate != this.item.startDate) {
                    this.activeStartDate = "";
                }
                return;
            }
        },
        sdtFocusOut() {
            if (this.activeStartDate != this.item.startDate) {
                this.activeStartDate = "";
            }
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#romuBankPg {
    width: 100%;
    height: 100vh;

    .main_area {
        width: 100%;
        height: 100%;
        padding: 78px 12% 28px;
        display: grid;
        grid-template-rows: 42px auto;

        .header {
            height: 42px;

            .staffName {
                font-size: 1.2rem;
                font-weight: bold;
            }
        }

        .main {
            display: grid;
            grid-template-columns: 240px auto;

            .rekilist_area {
                width: 240px;
                overflow: auto;

                .header_area {
                    padding: 6px;
                    border: 1px solid black;
                    background-color: #5e5e5e;
                }

                .reki_item {
                    padding: 6px;
                    border-bottom: 1px solid black;
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                }
            }

            .detail_area {
                padding: 0 16px;
                overflow: auto;

                .detail_kikan {

                    div {
                        display: inline-block;
                    }

                    .first_title {
                        width: 100px;
                        text-align: right;
                    }

                    .delim {
                        margin: 0 12px;
                    }

                    input[type="date"] {
                        height: 28px;
                    }
                }

                .detailPanel {
                    margin-top: 22px;

                    .name {
                        width: 100px;
                        text-align: right;

                        .icon {
                            width: 100%;
                            padding-right: 20px;
                            cursor: pointer;
                        }
                    }

                    .tblcell {
                        padding: 6px 0;
                        border-bottom: 1px solid black;
                    }

                    .accountType {
                        height: 28px;
                        border: 0;

                        &:focus {
                            border: 0;
                            outline: 0;
                        }
                    }

                    input[type="text"] {
                        padding: 3px;
                        text-align: right;
                        border: 0;

                        &:focus {
                            border: 0;
                            outline: 0;
                        }
                    }

                    .code {
                        display: inline-block;
                        width: 62px;
                        padding: 0 8px;
                        text-align: right;
                    }

                    .bname {
                        display: inline-block;
                        width: 240px;
                        padding: 0 8px;
                    }
                }

                .buttons_area {
                    text-align: center;
                    margin-top: 30px;
                    margin-right: 200px;
                    
                    .send {
                        width: 180px;
                        padding: 3px 12px;
                        margin-right: 12px;
                    }

                    .delete {
                        width: 180px;
                        padding: 3px 12px;
                        margin-left: 12px;
                        background-color: red;
                        border: 2px solid red;
                        color: white;
                    }
                }
            }
        }
    }

    #bankWindow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 480px;
        height: 580px;
        margin: auto;
        box-shadow: 3px 3px 8px 1px rgba(0,0,0,0.2);
        background-color: var(--backColor);
        border: 1px solid #acacac;
        border-radius: 8px;
        z-index: 99991;

        .header_area {
            .close-win {
                float: right;
                width: 32px;
                height: 32px;
                text-align: center;
                padding: 6px 0;
                cursor: pointer;
            }
        }

        .detail_area {
            margin-top: 32px;
            padding: 12px 20px;

            .tbl {
                width: 100%;
            }

            .tblcell {
                padding: 4px 0;
            }

            .title {
                width: 100px;
            }

            input[type="text"], select, option {
                height: 28px;
                padding: 0 4px;
            }

            .search_form {
                margin: 0;
                display: inline;
            }

            .search_area {
                position: relative;
                width: 100%;
                float: right;
                border: 1px solid #000;
                border-radius: 17px;
                padding: 6px 12px 6px 30px;

                .search-icon {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 6px;
                    margin: auto;
                    fill: #777777;
                }
                
                input[type=text] {
                    width: 100%;
                    border: 0;
                    height: 22px;

                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }
            }

            .banklist_area {
                margin-top: 12px;
                height: 180px;
                overflow: auto;

                .tblcell {
                    cursor: pointer;
                }

                .selected {
                    background-color: #748cb7;
                }
            }

            .branchlist_area {
                margin-top: 12px;
                height: 180px;
                overflow: auto;

                .tblcell {
                    cursor: pointer;
                }

                .selected {
                    background-color: #748cb7;
                }
            }
        }

        .footer_area {
            position: absolute;
            bottom: 24px;
            width: 100%;
            text-align: center;

            .btnOk {
                width: 90px;
                padding: 4px 12px;
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            margin-left: 20px;
        }
    }
}

@media(max-width:1027px) {
    #romuBankPg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;
        }
    }
}
</style>