<template>
    <div id="romuInfoPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">
                <div class="staffName">{{staffName}}</div>
            </div>
            <!-- メイン -->
            <div class="main">
                <div class="rekilist_area">
                    <div class="header_area">履歴</div>
                    <div class="reki_item" v-for="(itm, idx) in rekilist" :key="idx" @click.stop="openInfo(itm.startDate)">{{itm.startDate}}</div>
                </div>
                <div class="detail_area">
                    <div class="detail_kikan"><div class="first_title">開始日：</div><div><input type="date" v-model="item.startDate" @blur="sdtFocusOut" @keyup="edit_keyup"/></div><span class="delim">〜</span><span>終了日：</span><div><input type="date" v-model="item.endDate" /></div></div>
                    <div class="detailPanel tbl">
                        <div class="tblrow">
                            <div class="name tblcell">給与タイプ：</div>
                            <!-- <div class="value tblcell"><input type="text" class="salaryType" v-model="item.salaryType" /></div> -->
                            <div class="value tblcell">
                                <select class="salaryType" v-model="item.salaryType">
                                    <option v-for="(itm, idx) in salaryTypes" :key="idx" :value="itm.value">{{itm.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">基本給：</div>
                            <div class="value tblcell"><input type="text" v-model="item.kihonkyu" @blur="focusOut('kihonkyu')" @focus="focusIn('kihonkyu')" />円</div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">交通費：</div>
                            <div class="value tblcell"><input type="text" v-model="item.koutsuhi" @blur="focusOut('koutsuhi')" @focus="focusIn('koutsuhi')"/>円</div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">標準月額：</div>
                            <div class="value tblcell"><input type="text" v-model="item.hyojunGetsuGaku" @blur="focusOut('hyojunGetsuGaku')" @focus="focusIn('hyojunGetsuGaku')"/>円</div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">役員：</div>
                            <div class="value tblcell"><label><input type="checkbox" v-model="item.officerFlag" />&nbsp;役員である</label></div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">扶養人数：</div>
                            <div class="value tblcell"><input type="number" v-model="item.fuyoCount" />人</div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">住民税：</div>
                            <div class="value tblcell"><input type="text" v-model="item.jyuminTax" @blur="focusOut('jyuminTax')" @focus="focusIn('jyuminTax')"/>円</div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">固定手当：<div class="icon"><span @click.stop="addTeate">＋</span></div></div>
                            <div class="value tblcell">
                                <div class="teate_list tbl">
                                    <div class="tblrow">
                                        <div class="fixedcell code tblcell">コード</div>
                                        <div class="fixedcell tblcell">金額</div>
                                    </div>
                                    <div class="tblrow" v-for="(itm, idx) in item.teate" :key="idx">
                                        <div class="key tblcell"><input type="text" v-model="itm.key" /></div>
                                        <div class="value tblcell"><input type="text" v-model="itm.value" @blur="focusOutIx('teate', idx)" @focus="focusInIx('teate', idx)"/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">一時手当：<div class="icon"><span @click.stop="addEtcTeate">＋</span></div></div>
                            <div class="value tblcell">
                                <div class="teate2_list tbl">
                                    <div class="tblrow">
                                        <div class="fixedcell code tblcell">コード</div>
                                        <div class="fixedcell tblcell">金額</div>
                                    </div>
                                    <div class="tblrow" v-for="(itm, idx) in item.etcTeate" :key="idx">
                                        <div class="key tblcell"><input type="text" v-model="itm.key" /></div>
                                        <div class="value tblcell"><input type="text" v-model="itm.value" @blur="focusOutIx('etcTeate', idx)" @focus="focusInIx('etcTeate', idx)"/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tblrow">
                            <div class="name tblcell">現金支給：</div>
                            <div class="value tblcell"><label><input type="checkbox" v-model="item.furikomiFlag" />&nbsp;現金支給とする</label></div>
                        </div>
                    </div>
                    <div class="buttons_area">
                        <button class="send" @click.stop="saveData">登録</button><button class="delete" @click.stop="deleteData">削除</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * 個別労務情報管理画面
 */
import Vue from 'vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: "romuInfoPg",
    mixins: [utilityMixin],
    computed: {
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        activeStartDate(nv, ov) {
            if (this.activeStartDate != "") {
                this.getData();
            }
        },
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        }
    },
    data() {
        return {
            headerTitle: "労務情報",
            accountId: "",
            activeAccountId: "",
            activeStartDate: "",
            staffName: "",
            salaryTypes: [],
            rekilist: [],
            item: {},
            currencyValue: 0
        }
    },
    mounted() {
        if (!this.loginActiveCheck(true)) {
            return;
        }

        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", this.headerTitle);
            this.$store.dispatch("operator_baseClick", "");
            this.$store.dispatch("operator_backPanel", "");

            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;

            this.getRekiList()
        });
    },
    methods: {
        async getRekiList() {
            try {
                this.rekilist = [];
                
                let param = {
					staffId: this.activeAccountId
                };
                let ress = await Vue.$cache.post('romu/list/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.staffName = ress.data.data.name;
                        this.salaryTypes = ress.data.data.salaryTypes;
                        this.rekilist = ress.data.data.list;
                        if (this.rekilist.length > 0) {
                            this.activeStartDate = this.rekilist[0].startDate;
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        async getData() {
            try {
                let param = {
					staffId: this.activeAccountId,
                    startDate: this.activeStartDate,
                    userId: this.accountId
                };
                let ress = await Vue.$cache.post('romu/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.item = ress.data.data;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        saveData() {
            if (!confirm("登録しますか？")) {
                return false;
            }
            this.saveDataEx();
        },
        async saveDataEx() {
            try {
                let param = {
					staffId: this.activeAccountId,
                    startDate: this.item.startDate,
                    userId: this.accountId,
                    item: this.item
                };
                let ress = await Vue.$cache.post('romu/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.activeStartDate = "";
                        this.getRekiList();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        deleteData() {
            if (!confirm("削除しますか？")) {
                return false;
            }
            this.deleteDataEx();
        },
        async deleteDataEx() {
            try {
                let param = {
					staffId: this.activeAccountId,
                    startDate: this.item.startDate,
                    userId: this.accountId
                };
                let ress = await Vue.$cache.post('romu/delete/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.activeStartDate = "";
                        this.getRekiList();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                console.log(error);
            }
        },
        openInfo(startDate) {
            this.activeStartDate = startDate;
        },
        focusIn(prop) {
            this.currencyValue = this.item[prop].replace(",", "");
            this.item[prop] = this.currencyValue;
        },
        focusOut(prop) {
            this.currencyValue = this.item[prop].replace(",", "");
            if (this.currencyValue == "") {
                this.item[prop] = "";
            } else {
                this.item[prop] = Number(this.currencyValue).toLocaleString();
            }
        },
        focusInIx(prop, ix) {
            this.currencyValue = this.item[prop][ix].value.replace(",", "");
            this.item[prop][ix].value = this.currencyValue;
        },
        focusOutIx(prop, ix) {
            this.currencyValue = this.item[prop][ix].value.replace(",", "");
            if (this.currencyValue == "") {
                this.item[prop][ix].value = "";
            } else {
                this.item[prop][ix].value = Number(this.currencyValue).toLocaleString();
            }
        },
        edit_keyup(e) {
            if ((e.keyCode == 13) || (e.keyCode == 9)) {
                if (this.activeStartDate != this.item.startDate) {
                    this.activeStartDate = "";
                }
                return;
            }
        },
        sdtFocusOut() {
            if (this.activeStartDate != this.item.startDate) {
                this.activeStartDate = "";
            }
        },
        addTeate() {
            this.item.teate.push({"key":"", "value":""});
        },
        addEtcTeate() {
            this.item.etcTeate.push({"key":"", "value":""});
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#romuInfoPg {
    width: 100%;
    height: 100vh;

    .main_area {
        width: 100%;
        height: 100%;
        padding: 78px 12% 28px;
        display: grid;
        grid-template-rows: 42px auto;

        .header {
            height: 42px;

            .staffName {
                font-size: 1.2rem;
                font-weight: bold;
            }
        }

        .main {
            display: grid;
            grid-template-columns: 240px auto;

            .rekilist_area {
                width: 240px;
                overflow: auto;

                .header_area {
                    padding: 6px;
                    border: 1px solid black;
                    background-color: #5e5e5e;
                }

                .reki_item {
                    padding: 6px;
                    border-bottom: 1px solid black;
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                }
            }

            .detail_area {
                padding: 0 16px;
                overflow: auto;

                .detail_kikan {

                    div {
                        display: inline-block;
                    }

                    .first_title {
                        width: 100px;
                        text-align: right;
                    }

                    .delim {
                        margin: 0 12px;
                    }

                    input[type="date"] {
                        height: 28px;
                    }
                }

                .detailPanel {
                    margin-top: 22px;

                    .name {
                        width: 100px;
                        text-align: right;

                        .icon {
                            width: 100%;
                            padding-right: 20px;
                            cursor: pointer;
                        }
                    }

                    .tblcell {
                        padding: 6px 0;
                        border-bottom: 1px solid black;
                    }

                    .teate_list, .teate2_list {
                        width: 360px;

                        .tblcell {
                            padding: 2px 0;
                            border-right: 1px solid black;
                            border-bottom: 1px solid black;

                            &:first-child {
                                border-left: 1px solid black;
                            }
                        }

                        .fixedcell {
                            border-top: 1px solid black;
                            padding-left: 33px;
                            background-color: #5e5e5e;
                            text-align: center;
                            letter-spacing: 30px;

                            &.code {
                                letter-spacing: 12px;
                            }
                        }

                        .key {
                            input[type="text"], input[type="number"] {
                                text-align: left;
                            }
                        }
                    }

                    .salaryType {
                        height: 28px;
                        border: 0;

                        &:focus {
                            border: 0;
                            outline: 0;
                        }
                    }

                    input[type="text"], input[type="number"] {
                        padding: 3px;
                        text-align: right;
                        border: 0;

                        &:focus {
                            border: 0;
                            outline: 0;
                        }
                    }

                    input[type="number"] {
                        width: 48px;
                    }
                }

                .buttons_area {
                    text-align: center;
                    margin-top: 30px;
                    margin-right: 200px;
                    
                    .send {
                        width: 180px;
                        padding: 3px 12px;
                        margin-right: 12px;
                    }

                    .delete {
                        width: 180px;
                        padding: 3px 12px;
                        margin-left: 12px;
                        background-color: red;
                        border: 2px solid red;
                        color: white;
                    }
                }
            }
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            margin-left: 20px;
        }
    }
}

@media(max-width:1027px) {
    #romuInfoPg {
        .main_area {
            padding: 78px 0 28px;
            overflow: auto;
        }
    }
}
</style>