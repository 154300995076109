export default {
	// 1回限りの祝日
	// date:年/月/日, name:名称
	listHolidaysByYMD: [
		{ date:"1959/04/10", name:"皇太子明仁親王の結婚の儀" },
		{ date:"1989/02/24", name:"昭和天皇の大喪の礼" },
		{ date:"1990/11/12", name:"即位礼正殿の儀" },
		{ date:"1993/06/09", name:"皇太子徳仁親王の結婚の儀" },
		{ date:"2019/05/01", name:"天皇の即位の日"},
		{ date:"2019/10/22", name:"即位礼正殿の儀の行われる日"},
		{ date:"2020/07/23", name:"海の日"},
		{ date:"2020/07/24", name:"体育の日"},
		{ date:"2020/08/10", name:"山の日"},
		{ date:"2021/07/22", name:"海の日"},
		{ date:"2021/07/23", name:"体育の日"},
		{ date:"2021/08/08", name:"山の日"}
	],
	// 毎年決まった日付の祝祭日
	// date:月/日, name:名称, start:開始日, end:終了日
	listHolidaysByMonthDay: [
		{ date:"01/01", name:"元旦", start:"1949/01/01", end:"" },
		{ date:"01/15", name:"成人の日", start:"1949/01/01", end:"1999/01/15" },
		{ date:"02/11", name:"建国記念の日", start:"1967/01/01", end:"" },
		{ date:"02/23", name:"天皇誕生日", start:"2020/01/01", end:"" },
		{ date:"04/29", name:"天皇誕生日", start:"1949/01/01", end:"1988/04/29" },
		{ date:"04/29", name:"みどりの日", start:"1989/01/01", end:"2006/04/29" },
		{ date:"04/29", name:"昭和の日", start:"2007/01/01", end:"" },
		{ date:"05/03", name:"憲法記念日", start:"1949/01/01", end:"" },
		{ date:"05/04", name:"国民の休日", start:"1988/01/01", end:"2006/05/04" },
		{ date:"05/04", name:"みどりの日", start:"2007/01/01", end:"" },
		{ date:"05/05", name:"こどもの日", start:"1949/01/01", end:"" },
		{ date:"07/20", name:"海の日", start:"1996/01/01", end:"2002/07/20" },
		{ date:"08/11", name:"山の日", start:"2016/08/11", end:"2019/08/11" },
		{ date:"08/11", name:"山の日", start:"2022/08/11", end:"" },
		{ date:"09/15", name:"敬老の日", start:"1966/01/01", end:"2002/09/15" },
		{ date:"10/10", name:"体育の日", start:"1966/01/01", end:"1999/10/10" },
		{ date:"11/03", name:"文化の日", start:"1948/01/01", end:"" },
		{ date:"11/23", name:"勤労感謝の日", start:"1948/01/01", end:"" },
		{ date:"12/23", name:"天皇誕生日", start:"1989/01/01", end:"2018/12/23" }
	],
	// 毎年第X週指定曜日の祝祭日
	// m:月, s:週数, n:曜日番号(0=日曜〜6=土曜), name:名称, start:開始日, end:終了日
	listHolidaysByDayNum: [
		{ m:"1", s:"2", n:"1", name:"成人の日", start:"2000/01/01", end:"" },
		{ m:"7", s:"3", n:"1", name:"海の日", start:"2003/01/01", end:"2019/07/15" },
		{ m:"7", s:"3", n:"1", name:"海の日", start:"2022/01/01", end:"" },
		{ m:"9", s:"3", n:"1", name:"敬老の日", start:"2003/01/01", end:"" },
		{ m:"10", s:"2", n:"1", name:"体育の日", start:"2000/01/01", end:"2019/10/14" },
		{ m:"10", s:"2", n:"1", name:"体育の日", start:"2022/01/01", end:"" }
	],
	// 曜日表記
	dayNames:["日","月","火","水","木","金","土"],
	// 年号
	listNengo:[
		{ name:"天保", alphabet:"T", start:"1830/12/10" },
		{ name:"弘化", alphabet:"C", start:"1844/12/02" },
		{ name:"嘉永", alphabet:"K", start:"1848/02/28" },
		{ name:"安政", alphabet:"A", start:"1854/11/27" },
		{ name:"万延", alphabet:"M", start:"1860/03/18" },
		{ name:"文久", alphabet:"B", start:"1861/02/19" },
		{ name:"元治", alphabet:"G", start:"1864/02/20" },
		{ name:"慶応", alphabet:"K", start:"1865/04/07" },
		{ name:"明治", alphabet:"M", start:"1868/09/08" },
		{ name:"大正", alphabet:"T", start:"1912/07/30" },
		{ name:"昭和", alphabet:"S", start:"1926/12/25" },
		{ name:"平成", alphabet:"H", start:"1989/02/08" },
		{ name:"令和", alphabet:"R", start:"2019/05/01" }
	],
	// 春分の日
	getSpringDate(year) {
		let result;
		if (year >= 1949) {
			if ((year >= 1900) && (year <= 1979)) {
				let dat = this.Integral(20.8357 + (0.242194 * (year - 1980)) - (this.Integral((year - 1983) / 4))) / 1;
				result = year + "/03/" + dat;
			} else if ((year >= 1980) && (year <= 2099)) {
				let dat = this.Integral(20.8431 + (0.242194 * (year - 1980)) - (this.Integral((year - 1980) / 4))) / 1;
				result = year + "/03/" + dat;
			} else if ((year >= 2100) && (year <= 2150)) {
				let dat = this.Integral(21.8510 + (0.242194 * (year - 1980)) - (this.Integral((year - 1980) / 4))) / 1;
				result = year + "/03/" + dat;
			}
		}
		return result;
	},
	// 秋分の日
	getAutumnDate(year) {
		let result;
		if (year >= 1948) {
			if ((year >= 1900) && (year <= 1979)) {
				let dat = this.Integral(23.2588 + (0.242194 * (year - 1980)) - (this.Integral((year - 1983) / 4))) / 1;
				result = year + "/09/" + dat;
			} else if ((year >= 1980) && (year <= 2099)) {
				let dat = this.Integral(23.2488 + (0.242194 * (year - 1980)) - (this.Integral((year - 1980) / 4))) /1;
				result = year + "/09/" + dat;
			} else if ((year >= 2100) && (year <= 2150)) {
				let dat = this.Integral(24.2488 + (0.242194 * (year - 1980)) - (this.Integral((year - 1980) / 4))) / 1;
				result = year + "/09/" + dat;
			}
		}
		return result;
	},
	addTransferHolidays(list) {
		let result = new Array();
		
		for (let i=0; i < list.length; i++) {
			result.push(list[i]);
			let d = new Date(list[i].date);
			if (d.getDay() == 0) {
				let dw = new Date(1973, 3, 29);
				if (d.getTime() >= dw.getTime()) {
					d.setTime(d.getTime() + (24 * 3600 * 1000));
					dw = new Date(2008, 4, 4);
					if (d.getTime() >= dw.getTime()) {
						if (i + 1 < list.length) {
							let dat1 = list[i + 1];
							let wd = new Date(dat1.date);
							while (i + 1 < list.length && (d.getTime() == wd.getTime())) {
								result.push(list[++i]);
								d.setTime(d.getTime() + (24 * 3600 * 1000));
								dat1 = list[i + 1];
								wd = new Date(dat1.date);
							}
							let item = {
								"date": this.toChar(d),
								"title": "振替休日"
							}
							result.push(item);
						} else {
							let item = {
								"date": this.toChar(d),
								"title": "振替休日"
							}
							result.push(item);
						}
					} else {
						if (i+1 < list.length) {
							let dat1 = list[i + 1];
							let wd = new Date(dat1.date);
							if (!(d.getTime() == wd.getTime())) {
								let item = {
									"date": this.toChar(d),
									"title": "振替休日"
								}
								result.push(item);
							}
						} else {
							let item = {
								"date": this.toChar(d),
								"title": "振替休日"
							}
							result.push(item);
						}
					}
				}
			} else {
				d.setTime(d.getTime() + (24 * 3600 * 1000));
				if (d.getDay() == 6) { continue; }
				let dw = new Date(2008, 4, 4);
				if (d.getTime() >= dw.getTime()) {
					if (i+1 < list.length) {
						let dat = list[i+1];
						let d2 = new Date(dat.date);
						d2.setTime(d2.getTime() - (24 * 3600 * 1000));
						if (d.getTime() == d2.getTime()) {
							let item = {
								"date": this.toChar(d),
								"title": "国民の休日"
							}
							result.push(item);
						}
					}
				}
			}
		}
		return result;
	},
	// 該当年の祝祭日をリストアップする
	getHolidaysByYear(year) {
		let result = [];
		
		//-- 1日限定の祭日
		for (let i in this.listHolidaysByYMD) {
			let data = this.listHolidaysByYMD[i];
			let wy = data.date.split("/")[0];
			if (wy == year) {
				let item = {
					date: data.date,
					title: data.name
				}
				result.push(item);
			}
		}
		
		//-- 月日固定の祭日
		for (let i in this.listHolidaysByMonthDay) {
			let data = this.listHolidaysByMonthDay[i];
			let ymd = year + "/" + data.date;
			let item = {
				"date": ymd,
				"title": data.name
			};
			
			let wdate = new Date(ymd);
			let wstart = new Date(data.start);
			if (wdate.getTime() >= wstart.getTime()) {
				if (data.end != "") {
					let wend = new Date(data.end);
					if (wdate.getTime() <= wend.getTime()) {
						result.push(item);
					}
				} else {
					result.push(item);
				}
			}
		}
		
		//-- 曜日で変動の祭日
		for (let i in this.listHolidaysByDayNum) {
			let data = this.listHolidaysByDayNum[i];
			let wdate = this.getDateByDayNum(year, data.m, data.s, data.n);
			let item = {
				"date": this.toChar(wdate),
				"title": data.name
			};
			let wstart = new Date(data.start);
			if (wdate.getTime() >= wstart.getTime()) {
				if (data.end != "") {
					let wend = new Date(data.end);
					if (wdate.getTime() <= wend.getTime()) {
						result.push(item);
					}
				} else {
					result.push(item);
				}
			}
		}
		
		//-- 春分の日
		var item = {
			"date": this.getSpringDate(year),
			"title": "春分の日"
		}
		result.push(item);
		
		//-- 秋分の日
		item = {
			"date": this.getAutumnDate(year),
			"title": "秋分の日"
		}
		result.push(item);
		
		result.sort(this.sort);
		
		//--振替休日の追加
		result = this.addTransferHolidays(result);
		
		return result;
	},
	// 指定日での和暦情報を取得
	getNengoDataFromDate(date) {
		let result = {};
		let p = -1;
		for (let i=0; i < this.listNengo.length; i++) {
			var wdate = new Date(this.listNengo[i].start);
			if (date.getTime() > wdate.getTime()) {
				p = i;
				result = this.listNengo[i];
			}
		}
		if (p > -1) { return result; }
		return this.listNengo[0];
	},
	// 指定日での和暦年を取得
	getWarekiYear(date) {
		var data = this.getNengoDataFromDate(date);
		var y = 0;
		var wd = new Date(data.start);
		if (wd.getFullYear() > 0) { y = date.getFullYear() - (wd.getFullYear() - 1); }
		return y;
	},
	// 指定年の第X週Y曜日の日付を取得
	getDateByDayNum(y, m, s, n) {
		let date = new Date(y, (m - 1), 1);
		let dd = date.getDay();
		let f = ((n - dd) < 0) ? (n - dd + 7) : (n - dd);
		date = new Date(y, (m - 1), (1 + f));
		if (s > 1) {
			let wdate = new Date(y, (m - 1), date.getDate());
			wdate.setTime(wdate.getTime() + ((7 * (s - 1)) * 24 * 3600 * 1000));
			date = wdate;
		}
		if (date.getMonth() != (m - 1)) {
			return null;
		}
		return date;
	},
	warekiToSeireki(g, year) {
		let y = 0;
		let i = this.getNengoIndex(g);
		if (i === "") { return ""; }
		let yy = new Date(this.listNengo[i].start).getFullYear();
		if (yy > 0) { y = (yy - 1) + (year - 0); }
		return y;
	},
	getNengoIndex(g) {
		let index = -1;
		
		for (let i in this.listNengo) {
			let data = this.listNengo[i];
			if (g.match(/^([A-Z]|[a-z])$/)) {
				if (g.toUpperCase() == data.alphabet) {
					index = i;
					break;
				}
			} else {
				if (g.substr(0, 1) == data.name.substr(0, 1)) {
					index = i;
					break;
				}
			}
		}
		
		return index;
	},
	// フォーマット変換
	toChar(date) {
		let format = "yyyy/MM/dd";
		format = format.replace(/yyyy/g, date.getFullYear());
		format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
		format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
		return format;
	},
	// 数値の丸め
	Integral(num) {
		let res;
		if (num < 0) {
			res = Math.ceil(num);
		} else {
			res = Math.floor(num);
		}
		return res;
	},
	// ソート
	sort(a, b) {
		var ad = new Date(a.date);
		var bd = new Date(b.date);
		return ((ad.getTime() < bd.getTime()) ? -1 : ((ad.getTime() > bd.getTime()) ? 1 : 0));
	}
};
