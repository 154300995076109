<template>
    <tr class="acmanaged-row" :class="{'enabled':useMode}" @click.stop="openMeisai()">
        <td class="pdf" @click.stop="empty_click"><input type="checkbox" v-model="item.checked"/></td>
        <td class="employNo">{{cvtEmploy(item.employNo)}}</td>
        <td class="name">{{item.name}}</td>
        <td class="age">{{item.age}}</td>
        <td class="confirm">{{confirmText}}</td>
        <td class="hireDate">{{item.hireDate}}</td>
        <td class="kakutei">{{cvtKengen(item.authority)}}</td>
        <td class="syouka">{{item.email}}</td>
        <td class="edit">
            <span @click.stop="openEditor()">
                <svg height="16px" viewBox="0 0 24 24" width="16px">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                </svg>
            </span>
        </td>
        <td class="bank">
            <span @click.stop="openBankEditor()">
                <svg height="16px" viewBox="0 0 24 24" width="16px">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                </svg>
            </span>
        </td>
        <td></td>
    </tr>
</template>

<script>
export default {
    props: {
        item: null
    },
    computed: {
        useMode() {
            return (this.item.useFlag == 1) ? false : true;
        },
        confirmText() {
            return (this.item.confirmFlag) ? "◯" : "";
        }
    },
    watch: {
        'item.checked'(nv, ov) {
            this.$emit('changeChecked', nv);
        }
    },
    methods: {
        cvtEmploy(val) {
            if (val == 0) {
                val = "0001";
            } else {
                val = ((1000 + (val - 0)) + "");
            }
            return val;
        },
        cvtKengen(val) {
            let kengen = "";
            switch (val) {
                case 99:
                    kengen = "全権";
                    break;
                case 10:
                    kengen = "労務管理";
            }

            return kengen;
        },
        openMeisai() {
            this.$emit('openMeisai', this.item.employNo);
        },
        openEditor() {
            this.$emit('openEditor', this.item.employNo);
        },
        openBankEditor() {
            this.$emit('openBankEditor', this.item.employNo);
        },
        empty_click() {
            
        }
    }
}
</script>

<style lang="less">
.acmanaged-row {
    position: relative;

    td {
        padding: 6px 4px;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        white-space: nowrap;

        &:last-child {
            border-right: 1px solid black;
        }
    }

    .pdf, .hireDate, .age, .confirm {
        text-align: center;
    }

    .edit, .bank {
        text-align: center;

        span {
            cursor: pointer;
            svg {
                fill: #FFFFFF;
            }
        }
    }
}
.acmanaged-row.enabled {
    background-color: var(--grayoutBackColor);
}
</style>